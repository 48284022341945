import React, { useEffect, useMemo, useState } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate, useParams, useResolvedPath } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// Custom Components
import ContentPage, { ContentPageHeader } from '../../components/Page/ContentPage'

// constants
import {
  APP_TITLE,
  LANGUAGE_DICTIONARY_DE,
  ROUTE_BOTID_TRANSLATIONS_ANSWERS,
  ROUTE_BOTID_TRANSLATIONS_DIALOG,
  ROUTE_BOTID_TRANSLATIONS_LANG,
  ROUTE_BOTID_TRANSLATIONS_TERMS,
} from '../../utils/constants'

import { Grid } from '@mui/material'
import SubpageCard from 'components/Cards/SubpageCard'

import svgDialog from '../../assets/img/translations/translations_dialog.svg'
import svgTerms from '../../assets/img/translations/translations_technical_terms.svg'
import svgAnswers from '../../assets/img/translations/translations_answer.svg'
import { LockingContextProvider } from 'hooks/contexts/locking-context'
import DialogTranslationsOverview from './LanguageTranslations/DialogTranslations/DialogTranslations'
import { TermTranslationsContextProvider, useTermTranslationsContext } from 'hooks/contexts/term-translations-context'
import TermTranslations from './LanguageTranslations/TechnicalTermsTranslations/TermTranslations'
import LanguagesOverview from './LanguageTranslations/LanguagesOverview'
import { TranslationsContextProvider, useTranslationsContext } from 'hooks/contexts/translations-context'
import { ChartContextProvider } from 'hooks/contexts/chart-context'
import { loadDictionary as loadTermsDictionary } from 'hooks/contexts/term-translations-context'
import { areAllTextsOfLanguageTranslated } from 'utils/translationFileUtils'
import { useBotContext } from 'hooks/contexts/bot-context'
import { BotInfos } from '../../@types/BotInformation/types'
import { Dictionary } from '../../@types/Knowledge/Dictionaries/types'
import { areAllTechnicalTermsTranslated } from 'utils/technicalTermsUtils'
import AnswerTranslations from './LanguageTranslations/AnswerTranslations/AnswerTranslations'
import { AnswersContextProvider, useAnswers } from 'hooks/contexts/answers-context'
import CircularLoading from 'components/Loading/CircularLoading'
import { areAllTextsOfAnswersTranslated } from 'utils/answerUtils'
import { DatamangementContextProvider } from 'hooks/contexts/datamanagement-context'

function TranslationsMenu(): React.ReactElement {
  const navigate = useNavigate()
  const { bot } = useBotContext() as { bot: BotInfos }
  const { translationFile } = useTranslationsContext()
  const { termTranslations } = useTermTranslationsContext()
  const { flaggedAnswers } = useAnswers()
  const url = useResolvedPath('').pathname
  const { langCode } = useParams() as { langCode: string }
  const isPrimaryLanguage = langCode === translationFile?.primaryLanguage

  const nrTranslated = useMemo(() => {
    if (!translationFile) return { totalTexts: '-', translatedTexts: '-' }
    return areAllTextsOfLanguageTranslated(translationFile, langCode)
  }, [translationFile])

  const nrTranslatedTechnicalTerms = useMemo(() => {
    if (!termTranslations) return { totalTerms: '-', translatedTerms: '-' }
    return areAllTechnicalTermsTranslated(termTranslations, langCode)
  }, [termTranslations])

  const nrTranslatedAnswerTexts = useMemo(() => {
    if (!flaggedAnswers || !flaggedAnswers.answers) return { nrTotalTexts: '-', nrTranslatedTexts: '-' }
    return areAllTextsOfAnswersTranslated(flaggedAnswers.answers, bot.primaryLanguage, langCode)
  }, [flaggedAnswers])

  return (
    <div>
      <Helmet>
        <title>{APP_TITLE} - Übersetzungen</title>
      </Helmet>
      <ContentPageHeader
        title={`Übersetzungen (${LANGUAGE_DICTIONARY_DE[langCode]})`}
        previousUrl={url.substring(0, url.lastIndexOf('/'))}
      />
      <Grid container spacing={6}>
        <Grid item>
          <SubpageCard
            graphic={svgDialog}
            graphicAlt='Grafik: Dialog Translations'
            title='Konversationfluss'
            description={
              !isPrimaryLanguage
                ? `Übersetzungen für Ihre Texte aus dem Dialog Designer und aus Smart Cards.\n\n${nrTranslated?.translatedTexts} von ${nrTranslated?.totalTexts} Texten übersetzt.`
                : `Texte aus Konversationsfluss und den Smart Cards.\n\n${nrTranslated?.totalTexts} Texte.`
            }
            onClick={(): void => {
              navigate(url + ROUTE_BOTID_TRANSLATIONS_DIALOG)
            }}
            disabled={isPrimaryLanguage}
            hideButton={isPrimaryLanguage}
          />
        </Grid>
        <Grid item>
          <SubpageCard
            graphic={svgAnswers}
            graphicAlt='Grafik: Antworten'
            title='Wissensdatenbank'
            description={
              !isPrimaryLanguage
                ? `Übersetzungen für alle Antworten und Texte aus der Wissensdatenbank.\n\n${nrTranslatedAnswerTexts?.nrTranslatedTexts} von ${nrTranslatedAnswerTexts?.nrTotalTexts} Texten.`
                : `Texte aus der Wissensdatenbank.\n\n${nrTranslatedAnswerTexts?.nrTotalTexts} Texte.`
            }
            onClick={(): void => {
              navigate(url + ROUTE_BOTID_TRANSLATIONS_ANSWERS)
            }}
            disabled={isPrimaryLanguage}
            hideButton={isPrimaryLanguage}
          />
        </Grid>
        <Grid item>
          <SubpageCard
            graphic={svgTerms}
            graphicAlt='Grafik: Terms'
            title='Wörterbuch für Fachbegriffe'
            description={
              langCode === translationFile?.primaryLanguage
                ? `Zum Anlegen spezifischer Fachbegriffe.\n\n${
                    Object.keys(termTranslations ?? {}).length
                  } Fachbegriffe.`
                : `Zur Hinterlegung von dedizierten Übersetzungen für Fachbegriffe.\n\n${nrTranslatedTechnicalTerms.translatedTerms} von ${nrTranslatedTechnicalTerms.totalTerms} Fachbegriffe übersetzt.`
            }
            onClick={(): void => {
              navigate(url + ROUTE_BOTID_TRANSLATIONS_TERMS)
            }}
            disabled={false}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default function Translations(): React.ReactElement {
  const { pathname: path } = useLocation()
  const { bot } = useBotContext()

  return (
    <ContentPage>
      {/* Routes immer in Switch */}
      {/* NOTE: we have to have this ugly structure here because we cannot have nested lock contexts right now.
            This should be fixed, once we enable a locking context to have multiple locks for different scopes.       
        */}
      {bot && (
        <Routes>
          <Route
            path='*'
            element={
              <LockingContextProvider
                lockScopes={['flowdesigner-and-translations', 'translations-technical-terms', 'knowledgeNlu']}
              >
                <ChartContextProvider>
                  {' '}
                  <AnswersContextProvider>
                    <TranslationsContextProvider>
                      <TermTranslationsContextProvider>
                        <Routes>
                          {/* Language Selection */}
                          <Route path={''} element={<LanguagesOverview />} />

                          {/* Menu within a language */}
                          <Route path={ROUTE_BOTID_TRANSLATIONS_LANG} element={<TranslationsMenu />} />

                          {/* actual translation pages */}
                          {/* Technical Terms */}
                          <Route
                            path={ROUTE_BOTID_TRANSLATIONS_LANG + ROUTE_BOTID_TRANSLATIONS_TERMS + '/*'}
                            element={
                              /* Technical Terms Translation Dictionary */
                              <TermTranslations />
                            }
                          />

                          {/* Dialog Translations */}
                          <Route
                            path={ROUTE_BOTID_TRANSLATIONS_LANG + ROUTE_BOTID_TRANSLATIONS_DIALOG + '/*'}
                            element={<DialogTranslationsOverview />}
                          />

                          {/* Answer Translations */}
                          <Route
                            path={ROUTE_BOTID_TRANSLATIONS_LANG + ROUTE_BOTID_TRANSLATIONS_ANSWERS + '/*'}
                            element={
                              <DatamangementContextProvider>
                                <AnswerTranslations />
                              </DatamangementContextProvider>
                            }
                          />

                          {/* Catch rest and re-route */}
                          <Route path='*' element={<Navigate to={path} />} />
                        </Routes>
                      </TermTranslationsContextProvider>
                    </TranslationsContextProvider>
                  </AnswersContextProvider>
                </ChartContextProvider>
              </LockingContextProvider>
            }
          />
        </Routes>
      )}
    </ContentPage>
  )
}
