import { Chip, Grid, IconButton, Typography } from '@mui/material'
import EditableMarkdownText from 'components/TextInput/EditableMarkdownText'
import EditableTypography from 'components/TextInput/EditableTypography'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { theme } from 'theme/StudioTheme'
import { RAGCurrentlyIngestingDocument, RAGDocument, RAGDocumentType } from '../../../../@types/Knowledge/RAG/types'
import { getCompleteDateTimeString, getEuropeanDateTimeString } from 'utils/dateUtils'
import XzuFiIcon from 'assets/img/fileIcons/xzufi.svg'
import WordIcon from 'assets/img/fileIcons/word.svg'
import PDFIcon from 'assets/img/fileIcons/pdf.svg'
import WebsiteIcon from 'assets/img/fileIcons/html.svg'
import TxtIcon from 'assets/img/fileIcons/txt.svg'
import StringIcon from 'assets/img/fileIcons/string.svg'
import CircularLoading from 'components/Loading/CircularLoading'
import TrafficLight from 'components/TrafficLight/TrafficLight'

type RAGRow = {
  classes: any
  document: RAGDocument | RAGCurrentlyIngestingDocument
  index: number
  onDelete: (documentId: string) => void
  onEdit: (documentId: string) => void
  errorMsg?: string
  disableInteraction: boolean
}
const CHIP_CONFIG: { [key in RAGDocumentType]: { label?: string; color?: string; icon: string } } = {
  txt: {
    icon: TxtIcon,
    label: '.txt Datei',
  },
  string: {
    icon: StringIcon,
    label: 'Text',
  },
  website: {
    icon: WebsiteIcon,
    label: 'Website',
  },
  pdf: {
    icon: PDFIcon,
    label: '.pdf Datei',
  },
  docx: {
    icon: WordIcon,
    label: '.docx Datei',
  },
  xzufi: {
    icon: XzuFiIcon,
    label: 'XZuFi',
  },
}

function getTypeIcon(document: RAGDocument | RAGCurrentlyIngestingDocument): React.ReactNode {
  let type = 'txt'
  if (document.type === 'ingest-job') {
    // currently running ingest for that doc
    type = document.documentType
  } else {
    type = document.type
  }

  return (
    // <Chip
    //   size='medium'
    //   // color='secondary'
    //   variant='outlined'
    //   label={
    //     <>
    //       {/* {<img src={CHIP_CONFIG[type].icon} style={{ height: '16px', width: '16px' }} />} */}
    //       {CHIP_CONFIG[type].label ?? ''}
    //     </>
    //   }
    //   style={{ color: CHIP_CONFIG[type].color, borderColor: CHIP_CONFIG[type].color }}
    // />
    <div style={{ height: '32px', width: '32px' }}>
      <CustomizedTooltip
        placement='top'
        elements={<img src={CHIP_CONFIG[type].icon} style={{ height: '100%', width: '100%' }} />}
        content={CHIP_CONFIG[type].label}
      />
    </div>
  )
}

// we cannot have a React component as row of our Table so we just use a simple function.
// this means we have to provide everything "reacty" via props as we cannot use contexts for example.
export default function getKnowledgeRagDocumentRow({
  classes,
  document,
  index,
  errorMsg,
  onDelete,
  onEdit,
  disableInteraction,
}: RAGRow): React.ReactNode[] {
  console.log('document', document)
  return [
    <div key={`row-${index}-status`} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      {(document.type !== 'ingest-job' && document.updateStatus !== 'running') ||
      (document.type === 'ingest-job' && document.status === 'error') ? (
        <TrafficLight
          size='1rem'
          color={
            document.type === 'ingest-job' && document.status === 'error'
              ? // ingest job failed (no existing version of the document)
                'red'
              : (document as unknown as RAGDocument).updateStatus === 'error'
              ? // ingest job failed (existing version of the document; update failed.)
                'yellow'
              : document.type !== 'ingest-job'
              ? // existing document - no ingest running atm
                'green'
              : 'off'
          }
          tooltips={{
            green: 'Dokument ist live verfügbar und wird für die Antwortfindung genutzt.',
            yellow:
              'Aktualisierung fehlgeschlagen! Die letzte Version des Dokuments wird weiter genutzt. Stoßen Sie das Update erneut an, um die Aktualisierung zu wiederholen.',
            red: 'Import fehlgeschlagen! Dokument ist nicht live verfügbar und wird nicht für die Antwortfindung genutzt.',
          }}
        />
      ) : (
        <CircularLoading
          size='small'
          tooltip='Dokument wird verarbeitet. Dies kann einen Moment dauern. Während der Verarbeitung steht das Dokument noch nicht live zur Verfügung.'
        />
      )}
    </div>,
    <div key={`row-${index}-type`} style={{ display: 'flex' }}>
      {getTypeIcon(document)}
      {/* <Chip
        size='medium'
        // color='secondary'
        variant='outlined'
        label={CHIP_CONFIG[document.type].label ?? ''}
        style={{ color: CHIP_CONFIG[document.type].color, borderColor: CHIP_CONFIG[document.type].color }}
      /> */}
    </div>,
    <div key={`row-${index}-title`}>
      <div style={{ display: 'flex' }}>
        <Typography variant='body2' style={{ wordWrap: 'break-word' }} component={'p'}>
          {document.title || '-'}
        </Typography>
      </div>
    </div>,
    <div key={`row-${index}-extra-info`}>
      {document.type === 'website' && document.website?.website_sync_schedule ? (
        // <div style={{ height: '32px', width: '32px' }}>
        <CustomizedTooltip
          placement='top'
          elements={
            // <div style={{ display: 'flex', height: '100%', width: '100%' }}>
            <i
              className='ri-time-line'
              style={{
                fontSize: '1.5rem',
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            />
            // </div>
          }
          content={<Typography>Automatische Aktualisierung aktiviert</Typography>}
        />
      ) : // </div>
      null}
    </div>,
    <div key={`row-${index}-last-changed`}>
      <Chip
        size='medium'
        variant='outlined'
        color='primary'
        label={getEuropeanDateTimeString(
          document.type === 'ingest-job' ? document.timestamp_enqueuing : document.lastChanged,
        )}
      />
    </div>,
    <div key={`row-${index}-actions`} style={{ display: 'flex', justifyContent: 'end' }}>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          {(document.type === 'xzufi' && (document.sourceUrl || document.xzufi.xzufi_portal_url)) ||
          (document.type === 'website' && document.sourceUrl) ? (
            <CustomizedTooltip
              placement='top'
              elements={
                <span>
                  <IconButton
                    onClick={(e: React.MouseEvent): void => {
                      e.preventDefault()
                      e.stopPropagation()
                      if (document.type === 'website') {
                        window.open(document.sourceUrl, '_blank')
                      } else if (document.type === 'xzufi') {
                        const url = document.sourceUrl ? document.sourceUrl : document.xzufi.xzufi_portal_url
                        window.open(url, '_blank')
                      }
                    }}
                    className={classes.deleteCardButton}
                  >
                    <i className='ri-external-link-line'></i>
                  </IconButton>
                </span>
              }
              content={<Typography>Inhalt öffnen</Typography>}
            />
          ) : (
            <div />
          )}
        </Grid>
        <Grid item xs={4}>
          <CustomizedTooltip
            placement='top'
            elements={
              <span>
                <IconButton
                  onClick={(e: React.MouseEvent): void => {
                    onEdit(document.documentId)
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  disabled={
                    disableInteraction ||
                    document.type === 'xzufi' ||
                    document.type === 'website' ||
                    document.type === 'ingest-job' ||
                    document.updateStatus === 'running'
                  }
                  className={classes.deleteCardButton}
                >
                  <i className='ri-pencil-line'></i>
                </IconButton>
              </span>
            }
            content={<Typography>Inhalt bearbeiten</Typography>}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomizedTooltip
            placement='top'
            elements={
              <span>
                <IconButton
                  onClick={(e: React.MouseEvent): void => {
                    onDelete(document.documentId)
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  disabled={disableInteraction}
                  className={classes.deleteCardButton}
                >
                  <i className='ri-delete-bin-4-line'></i>
                </IconButton>
              </span>
            }
            content={<Typography>Dokument löschen</Typography>}
          />
        </Grid>
      </Grid>
    </div>,
  ]
}
