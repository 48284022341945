import React, { useEffect, useState } from 'react'

// Material Ui
import { makeStyles } from 'tss-react/mui'

import Typography from '@mui/material/Typography'
// Custom Components
import SelectDropdown, { Option, ActionMeta } from '../../components/Dropdown/SelectDropdown'
import AnalyticsCard from './AnalyticsCard'
import SelectSpecificDayDialog from './SelectSpecificDayDialog'
import SelectDateRangeDialog from './SelectDateRangeDialog'
import TimeseriesLinechart, { DataSeries } from '../../components/Charts/Linechart/TimeseriesLinechart'
import DayOfWeekHeatmap from '../../components/Charts/Heatmap/DayOfWeekHeatmap'
import CustomizedTooltip from 'components/Tooltips/CustomContentTooltip'
import Table from 'components/Table/Table'
// Types
import {
  TimespanOptionValue,
  TimespanOptions,
  ChartName,
  Granularity,
  Scope,
  Timespan,
  Language,
  ChartType,
  DetailedCountResult,
  ScaleType,
  TitleValue,
  DetailedCountResultPerTime,
  WordcloudData,
} from '../../@types/Analytics/types'
import { addDaysToDate, subtractDaysFromDate } from 'utils/dateUtils'
import { isEmpty, isEqual } from 'lodash'
import { Button } from 'components/Buttons'
import Wordcloud from 'components/Charts/Wordcloud/Wordcloud'

const useStyles = makeStyles()((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    padding: '2px 2px 0 0',
  },
  chartContainer: {
    flexGrow: 1,
  },
  extraButton: {
    marginRight: theme.spacing(2),
  },
  actionContainer: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
  },
}))

interface AnalyticsChartBaseProps {
  id: ChartName
  title: string
  shouldReload?: boolean
  titleValueChar?: string
  switchTitleValueAndTitleValueChar?: boolean
  showTitleValue?: boolean
  titleValue?: TitleValue
  isLoading: boolean
  extraButtonTitle?: string
  extraButtonCallback?: () => void
  onLoadDataCallback: (
    scopes: Scope[],
    timespan: Timespan,
    granularity: Granularity,
    chartType: ChartType,
    chartName: ChartName,
    languages?: Language[],
    totalSumOnly?: boolean,
  ) => void
  onCustomTimespanSelection: (fromDate: Date, toDate: Date) => void
  scopes: Scope[] // all scopes that can be viewed in chart
  granularity: Granularity
  xAxisLabel?: string
  yAxisLabel?: string
  scaleType?: ScaleType
  height?: string
  customTimespanSuggestion?: {
    fromDate: Date
    toDate: Date
  }
}

interface LinechartChartProps extends AnalyticsChartBaseProps {
  chartType: 'linechart'
  linechartXValues: Date[]
  linechartYValues: DataSeries[]
  wordcloudData?: undefined
  heatmapData?: undefined
  formatTooltipValue?: (val: number, opts?: any) => string // optional custom tooltip formatter function
  rows?: undefined
  width?: undefined
  headers?: undefined
  cellAlignPattern?: undefined
}

interface HeatmapChartProps extends AnalyticsChartBaseProps {
  chartType: 'heatmap'
  heatmapData?: DetailedCountResult['complete']
  linechartXValues?: undefined
  linechartYValues?: undefined
  wordcloudData?: undefined
  formatTooltipValue?: undefined
  rows?: undefined
  width?: undefined
  headers?: undefined
  cellAlignPattern?: undefined
}

interface TableProps extends AnalyticsChartBaseProps {
  chartType: 'table'
  rows: React.ReactNode[][]
  width?: string[]
  headers?: string[]
  cellAlignPattern?: ('left' | 'right' | 'inherit' | 'center' | 'justify')[]
  wordcloudData?: undefined
  linechartXValues?: undefined
  linechartYValues?: undefined
  formatTooltipValue?: undefined
  heatmapData?: undefined
}

interface WordcloudProps extends AnalyticsChartBaseProps {
  chartType: 'wordcloud'
  wordcloudData: WordcloudData
  // set other type properties to undefined
  linechartXValues?: undefined
  linechartYValues?: undefined
  heatmapData?: undefined
  formatTooltipValue?: undefined
  rows?: undefined
  width?: undefined
  headers?: undefined
  cellAlignPattern?: undefined
}

type AnalyticsChartProps = LinechartChartProps | HeatmapChartProps | TableProps | WordcloudProps

/**
 * Tooltip content for displaying total values of other series in chart
 */
function TitleValueTooltipContent({ series }: { series: TitleValue['series'] }): React.ReactElement {
  const seriesNames = Object.keys(series || {})
  return typeof series !== 'undefined' && !isEmpty(series) ? (
    <div>
      {seriesNames.map((name) => (
        <div key={name} style={{ display: 'flex' }}>
          {name}: {typeof series !== 'undefined' ? series[name] : '-'}
        </div>
      ))}
    </div>
  ) : (
    <></>
  )
}

export default React.memo(function AnalyticsChart({
  id,
  title,
  shouldReload,
  showTitleValue = true,
  titleValue: titleValueProps,
  isLoading: isLoadingProps,
  onLoadDataCallback,
  chartType,
  linechartXValues: origXValues,
  linechartYValues: origYValues,
  heatmapData: origHeatmapData,
  wordcloudData: origWordcloudData,
  scopes,
  granularity,
  scaleType,
  height = '340px',
  formatTooltipValue,
  xAxisLabel = '',
  yAxisLabel = '',
  titleValueChar = '#',
  switchTitleValueAndTitleValueChar = false,
  rows = [],
  width,
  headers,
  cellAlignPattern,
  customTimespanSuggestion,
  onCustomTimespanSelection,
  extraButtonTitle,
  extraButtonCallback,
}: AnalyticsChartProps): React.ReactElement {
  const { classes } = useStyles()

  const [timespanOptions, setTimespanOptions] = useState<TimespanOptions>([
    { label: 'Spezifischer Tag', value: 'specific_day' },
    { label: 'Letzte 24h', value: 'last_24h' },
    { label: 'Letzte 7 Tage', value: 'last_7d' },
    { label: 'Letzte 30 Tage', value: 'last_30d' },
    { label: 'Letzte 90 Tage', value: 'last_90d' },
    { label: 'Seit Jahresbeginn', value: 'since_start_of_year' },
    { label: 'Letztes Jahr', value: 'last_year' },
    { label: 'Gesamter Zeitraum', value: 'all_time' },
    { label: 'Benutzerdefiniert', selectedLabel: 'Benutzerdefiniert', value: 'custom' },
  ])

  // state - data
  const [isLoading, setIsLoading] = useState<boolean>(isLoadingProps)
  const [titleValue, setTitleValue] = useState<TitleValue>(titleValueProps ?? { primaryValue: ' ' })
  const [detailLevel, setDetailLevel] = useState<Granularity>(granularity)
  // if linechart, xValues and yValues
  const [xValues, setXValues] = useState<Date[]>()
  const [yValues, setYValues] = useState<DataSeries[]>()
  // if heatmap
  const [heatmapData, setHeatmapData] = useState<DetailedCountResultPerTime>()
  // if wordcloud
  const [wordcloudData, setWordcloudData] = useState<WordcloudData>()

  // state - visual
  const [displayDialog, setDisplayDialog] = useState<'specificDay' | 'customRange'>()
  const [prevSelectedValue, setPrevSelectedValue] = useState<TimespanOptionValue>('last_7d') // holds previously selected value for resetting on dialog close
  const [selectedValue, setSelectedValue] = useState<TimespanOptionValue>('last_7d') // holds selected dialog value
  const [selectedDate, setSelectedDate] = useState<Date>() // selected date, if specific day is selected
  const [selectedDateRange, setSelectedDateRange] = useState<{ from: Date; to: Date }>()

  /**
   * Triggers data retrieval from api
   */
  function onLoadData(timespan: Timespan, detailLevel: Granularity): void {
    setDetailLevel(detailLevel)
    onLoadDataCallback(scopes, timespan, detailLevel, chartType, id)
  }

  /**
   * Calculates timespan and triggers onLoadData.
   * Only receives a date object if timespanOptionValue === 'specific_day' or 'custom'
   * If 'custom', date is startDate and toDate is the end date of the range.
   *
   * @param timespanOptionValue
   * @param date (only if specific day or custom range)
   * @param toDate (only if custom range)
   */
  function calculateTimespanAndTriggerLoad(timespanOptionValue: TimespanOptionValue, date?: Date, toDate?: Date): void {
    switch (timespanOptionValue) {
      case 'specific_day': {
        if (typeof date === 'undefined') date = new Date() // if date is undefined, set to today - this should never be the case!
        date.setHours(0, 0, 0, 0)
        const endDate = addDaysToDate(date, 1)
        const timespan = { from: date, to: endDate }
        onLoadData(timespan, 'hour')
        break
      }
      case 'custom': {
        // custom date range
        if (typeof date === 'undefined') date = subtractDaysFromDate(new Date(), 7)
        if (typeof toDate === 'undefined') toDate = new Date()
        date.setHours(0, 0, 0, 0)
        toDate.setHours(23, 59, 59, 999)
        const timespan = { from: date, to: toDate }
        onLoadData(timespan, chartType === 'heatmap' ? 'hour' : 'day')
        onCustomTimespanSelection(date, toDate)
        break
      }
      case 'last_24h': {
        const endDate = new Date()
        const startDate = subtractDaysFromDate(endDate, 1)
        const timespan = { from: startDate, to: endDate }
        onLoadData(timespan, 'hour')
        break
      }
      case 'all_time': {
        const endDate = new Date()
        const startDate = new Date(2019, 0, 1, 0, 0)
        const timespan = { from: startDate, to: endDate }
        onLoadData(timespan, 'day')
        break
      }
      case 'since_start_of_year': {
        const endDate = new Date()
        const startDate = new Date()
        startDate.setMonth(0, 1)
        startDate.setHours(0, 0, 0, 0)
        const timespan = { from: startDate, to: endDate }
        onLoadData(timespan, 'day')
        break
      }
      case 'last_7d':
      case 'last_30d':
      case 'last_90d':
      case 'last_year': {
        // set end date to today 12am
        const endDate = new Date()
        endDate.setHours(23, 59, 59, 999)

        const daysFromDate =
          timespanOptionValue === 'last_7d'
            ? 7
            : timespanOptionValue === 'last_30d'
            ? 30
            : timespanOptionValue === 'last_90d'
            ? 90
            : 365

        const startDate = subtractDaysFromDate(endDate, daysFromDate)
        startDate.setHours(0, 0, 0, 0)
        const timespan = { from: startDate, to: endDate }
        onLoadData(timespan, chartType === 'heatmap' ? 'hour' : 'day')
        break
      }
    }
  }

  /**
   * Handles timespan change.
   * Calculates new timespan object (from and to dates) and also specifies granularity.
   * @param value
   */
  function onTimespanDropdownSelect(value: TimespanOptionValue): void {
    if (value === 'specific_day') setDisplayDialog('specificDay')
    else if (value === 'custom') setDisplayDialog('customRange')
    else calculateTimespanAndTriggerLoad(value)

    setPrevSelectedValue(selectedValue)
    setSelectedValue(value)
  }

  /**
   * Handles dialog close event.
   * Resets selected value to previously selected one
   */
  function onDialogClose(): void {
    setSelectedValue(prevSelectedValue)
    setDisplayDialog(undefined)
  }

  /**
   * Handles date selection in select specific date dialog.
   * Builds timespan object and calls onLoadData
   * @param date selected date
   * @param timespanOptionValue selected timespan value of dropdown, used to determine how to build timespan object
   */
  function onSelectDate(date: Date, timespanOptionValue: TimespanOptionValue): void {
    calculateTimespanAndTriggerLoad(timespanOptionValue, date)
    setDisplayDialog(undefined)
    setSelectedDate(date)
  }

  /**
   * Handles custom date range selection in select date range dialog.
   * Builds timespan object and calls onLoadData
   * @param from
   * @param to
   * @param timespanOptionValue selected timespan value of dropdown, used to determine how to build timespan object
   */
  function onSelectDateRange(from: Date, to: Date, timespanOptionValue: TimespanOptionValue): void {
    // trigger load
    calculateTimespanAndTriggerLoad(timespanOptionValue, from, to)
    // update dropdown options (change selectedLabel to selected range)
    const fromDate = from.getDate()
    const fromMonth = from.getMonth() + 1
    const toDate = to.getDate()
    const toMonth = to.getMonth() + 1
    const sFromDate = fromDate < 10 ? `0${fromDate}` : `${fromDate}`
    const sFromMonth = fromMonth < 10 ? `0${fromMonth}` : `${fromMonth}`
    const sToDate = toDate < 10 ? `0${toDate}` : `${toDate}`
    const sToMonth = toMonth < 10 ? `0${toMonth}` : `${toMonth}`

    const selectedLabel = `${sFromDate}.${sFromMonth}. - ${sToDate}.${sToMonth}.`

    const newTimespanOptions = [...timespanOptions]
    const optionIndex = newTimespanOptions.findIndex((option) => option.value === timespanOptionValue)
    if (optionIndex > -1) newTimespanOptions[optionIndex].selectedLabel = selectedLabel
    setTimespanOptions(newTimespanOptions)

    // set selected date range
    setDisplayDialog(undefined)
    setSelectedDateRange({ from, to })
  }

  useEffect(
    // trigger reload with the same timespan and detail level
    // calculates new timespan to take most recent events into consideration and triggers reload
    function () {
      if (shouldReload) {
        calculateTimespanAndTriggerLoad(selectedValue, selectedDate)
      }
    },
    [shouldReload],
  )

  useEffect(
    function () {
      if (typeof origXValues !== 'undefined') setXValues([...origXValues])
      if (typeof origYValues !== 'undefined') setYValues([...origYValues])
      if (typeof origHeatmapData !== 'undefined') setHeatmapData({ ...origHeatmapData })
      if (typeof origWordcloudData !== 'undefined') setWordcloudData({ ...origWordcloudData })
    },
    [origXValues, origYValues, origHeatmapData, origWordcloudData],
  )

  useEffect(
    function () {
      if (titleValueProps) {
        setTitleValue(titleValueProps)
      }
    },
    [titleValueProps],
  )

  useEffect(
    function () {
      setIsLoading(isLoadingProps)
    },
    [isLoadingProps],
  )

  return (
    <>
      <AnalyticsCard height={height}>
        <div className={classes.container}>
          <div className={classes.header}>
            <div>
              <div style={{ display: 'flex' }}>
                <Typography variant='overline'>{title}</Typography>
              </div>
              {showTitleValue ? (
                <CustomizedTooltip
                  placement='top'
                  content={
                    titleValue?.series && Object.keys(titleValue.series).length > 1 ? (
                      <TitleValueTooltipContent series={titleValue.series} />
                    ) : null
                  }
                  elements={
                    titleValue?.primaryValue ? (
                      <Typography variant='h2'>
                        {switchTitleValueAndTitleValueChar
                          ? `${titleValue.primaryValue} ${titleValueChar}`
                          : `${titleValueChar} ${titleValue.primaryValue}`}
                      </Typography>
                    ) : (
                      <></>
                    )
                  }
                />
              ) : null}
            </div>
            <div className={classes.actionContainer}>
              {extraButtonTitle && (
                <Button
                  color='primary'
                  className={classes.extraButton}
                  onClick={(event): void => {
                    event.preventDefault()
                    if (typeof extraButtonCallback !== 'undefined') extraButtonCallback()
                  }}
                >
                  {extraButtonTitle}
                </Button>
              )}
              <SelectDropdown
                onChange={(newValue: Option, action: ActionMeta<Option>): void => {
                  onTimespanDropdownSelect(newValue.value as TimespanOptionValue)
                }}
                selected={selectedValue}
                options={timespanOptions}
                maxDisplayOptions={5}
                width={'200px'}
                height={'56px'}
              />
            </div>
          </div>
          <div className={classes.chartContainer}>
            {chartType === 'linechart' ? (
              <TimeseriesLinechart
                linechartId={id}
                isLoading={isLoading}
                xValues={xValues}
                yValues={yValues}
                xAxisLabel={xAxisLabel}
                yAxisLabel={yAxisLabel}
                detailLevel={detailLevel}
                maxNumberXLabels={8}
                type={scaleType}
                formatTooltipValue={formatTooltipValue}
              />
            ) : chartType === 'heatmap' ? (
              <DayOfWeekHeatmap heatmapId={id} isLoading={isLoading} data={heatmapData} />
            ) : chartType === 'table' ? (
              <Table
                headers={headers}
                cellAlignPattern={cellAlignPattern}
                width={width}
                padding='medium'
                rows={rows}
                scrollTop={false}
                isLoading={isLoading}
                emptyMessage='Keine Daten für den angegebenen Zeitraum.'
              />
            ) : chartType === 'wordcloud' ? (
              <Wordcloud data={wordcloudData} loading={isLoading} />
            ) : null}
          </div>
        </div>
      </AnalyticsCard>
      {displayDialog === 'specificDay' && (
        <SelectSpecificDayDialog
          selectedDate={selectedDate}
          onClose={onDialogClose}
          onSelectDate={onSelectDate}
          timespanOptionValue={selectedValue || 'last_7d'}
        />
      )}
      {displayDialog === 'customRange' && (
        <SelectDateRangeDialog
          fromDate={selectedDateRange?.from ?? customTimespanSuggestion?.fromDate}
          toDate={selectedDateRange?.to ?? customTimespanSuggestion?.toDate}
          onClose={onDialogClose}
          onSelectDateRange={onSelectDateRange}
          timespanOptionValue={selectedValue || 'custom'}
        />
      )}
    </>
  )
}, isEqual)
