import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Divider, IconButton, ListItemText, Menu, MenuItem, MenuList, Typography } from '@mui/material'
import BaseCard from './BaseCard'
import { LANGUAGE_DICTIONARY_DE } from '../../utils/constants'
import * as translationFileUtils from 'utils/translationFileUtils'
import { TranslationFile } from '../../@types/Translations/types'
import { useTheme } from '@mui/material/styles'
import { MoreVert } from '@mui/icons-material'
import Warning from 'assets/img/knowledge/icons/warning'
import Check from 'assets/img/knowledge/icons/check'
import { LockState } from '../../@types/Locking/types'
import { useTranslationsContext } from 'hooks/contexts/translations-context'
import { useLockingContext } from 'hooks/contexts/locking-context'
import { Dictionary } from '../../@types/Knowledge/Dictionaries/types'
import { areAllTechnicalTermsTranslated } from 'utils/technicalTermsUtils'
import { useTermTranslationsContext } from 'hooks/contexts/term-translations-context'
import { useAnswers } from 'hooks/contexts/answers-context'
import { areAllTextsOfAnswersTranslated } from 'utils/answerUtils'
import { useBotContext } from 'hooks/contexts/bot-context'

type StyleProps = {
  isPrimaryLanguage?: boolean
}

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  card: {
    cursor: props.isPrimaryLanguage ? 'default' : 'pointer',
  },
  cardContent: {
    display: 'flex',
  },
  centerVertically: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  flexRow: {
    display: 'flex',
  },
  popupMenu: {
    padding: 0,
  },
  popupMenuList: {
    padding: 0,
  },
  popupMenuRedText: {
    color: theme.palette.error.main,
  },
}))

type LanguageCardMenuProps = {
  isActive: boolean
  language: string
  onChangeActiveState: () => void
  onEdit: () => void
  onDelete: () => void
  open: boolean
  onClose: () => void
  anchorElement: Element
}

/**
 * Menu component for menu button of card.
 */
function LanguageCardMenu({
  isActive,
  language,
  onChangeActiveState,
  onEdit,
  onDelete,
  open,
  onClose,
  anchorElement,
}: LanguageCardMenuProps): React.ReactElement {
  const { classes } = useStyles({})
  const { bot, hasNLUKnowledgeDB } = useBotContext()
  const { translationFile } = useTranslationsContext()
  const { termTranslations } = useTermTranslationsContext()
  const { flaggedAnswers } = useAnswers()
  const [isActivateLanguageButtonEnabled, setIsActivateLanguageButtonEnabled] = useState(false)

  useEffect(
    function checkActivateButton() {
      if (!bot || !translationFile || (hasNLUKnowledgeDB && !flaggedAnswers?.answers) || !termTranslations) return

      const allAnswersTranslated =
        hasNLUKnowledgeDB && flaggedAnswers
          ? areAllTextsOfAnswersTranslated(flaggedAnswers.answers, bot.primaryLanguage, language).complete
          : true
      const allTermsTranslated = areAllTechnicalTermsTranslated(termTranslations, language)
      const allFlowTextsTranslated = !!translationFileUtils.areAllTextsOfLanguageTranslated(translationFile, language)

      setIsActivateLanguageButtonEnabled(allAnswersTranslated && allTermsTranslated && allFlowTextsTranslated)
    },
    [flaggedAnswers, translationFile, termTranslations],
  )

  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorEl={anchorElement}
      classes={{ list: classes.popupMenuList }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          width: '150px',
        },
      }}
    >
      {/* <Paper sx={{ width: 320, maxWidth: '100%' }}> */}
      <MenuList>
        <MenuItem onClick={onChangeActiveState} disabled={!isActivateLanguageButtonEnabled}>
          <ListItemText>{isActive ? 'Deaktivieren' : 'Aktivieren'}</ListItemText>
        </MenuItem>
        <MenuItem onClick={onEdit}>
          <ListItemText>Bearbeiten</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={onDelete}>
          <ListItemText classes={{ primary: classes.popupMenuRedText }} primary='Löschen' />
        </MenuItem>
      </MenuList>
      {/* </Paper> */}
    </Menu>
  )
}

type LanguageCardProps = {
  languageCode: string
  // lockState: LockState
  onClick: (languageCode: string) => void
  onDelete: (languageCode: string) => void
  onChangeActiveState: (languageCode) => void
  disableInteraction?: boolean
  isPrimaryLanguage?: boolean
  isActive: boolean
}

export default function LanguageCard({
  languageCode,
  onClick,
  onDelete,
  onChangeActiveState,
  disableInteraction = false,
  isPrimaryLanguage = false,
  isActive,
}: LanguageCardProps): React.ReactElement {
  const { classes } = useStyles({ isPrimaryLanguage })
  const { bot, hasNLUKnowledgeDB } = useBotContext()
  const { translationFile } = useTranslationsContext()
  const { termTranslations } = useTermTranslationsContext()
  const { flaggedAnswers } = useAnswers()
  const { lockState } = useLockingContext()
  const theme = useTheme()
  const [totalTexts, setTotalTexts] = useState<number>(0)
  const [translatedTexts, setTranslatedTexts] = useState<number>(0)
  const [totalTechnicalTerms, setTotalTechnicalTerms] = useState<number>(0)
  const [translatedTechnicalTerms, setTranslatedTechnicalTerms] = useState<number>(0)
  const [totalKnowledgeTexts, setTotalKnowledgeTexts] = useState<number>(0)
  const [translatedKnowledgeTexts, setTranslatedKnowledgeTexts] = useState<number>(0)
  const [anchorElementForMenu, setAnchorElementForMenu] = useState<HTMLElement | null>(null)
  const menuOpen = Boolean(anchorElementForMenu)

  function handleMenuClose(): void {
    setAnchorElementForMenu(null)
  }

  function handleMenuClick(event: React.MouseEvent<HTMLButtonElement>): void {
    event.stopPropagation()
    if (menuOpen) {
      handleMenuClose()
    } else {
      setAnchorElementForMenu(event.currentTarget)
    }
  }

  useEffect(
    function () {
      if (!translationFile) return
      const checkResult = translationFileUtils.areAllTextsOfLanguageTranslated(translationFile, languageCode)
      if (!checkResult) return

      setTotalTexts(checkResult.totalTexts)
      setTranslatedTexts(checkResult.translatedTexts)
    },
    [translationFile, languageCode],
  )

  useEffect(
    function () {
      if (!termTranslations) return
      const checkResult = areAllTechnicalTermsTranslated(termTranslations, languageCode)

      setTotalTechnicalTerms(checkResult.totalTerms)
      setTranslatedTechnicalTerms(checkResult.translatedTerms)
    },
    [termTranslations, languageCode],
  )

  useEffect(
    function countAnswerTextTranslations() {
      if (!flaggedAnswers?.answers || !bot) return

      const checkResult = areAllTextsOfAnswersTranslated(flaggedAnswers.answers, bot.primaryLanguage, languageCode)
      setTotalKnowledgeTexts(checkResult.nrTotalTexts)
      setTranslatedKnowledgeTexts(checkResult.nrTranslatedTexts)
    },
    [flaggedAnswers, languageCode],
  )

  return (
    <>
      <BaseCard
        title={`${LANGUAGE_DICTIONARY_DE[languageCode]} (${languageCode})`}
        titleVariant='h3'
        onClick={(): void => onClick(languageCode)}
        disabled={disableInteraction}
        height='220px'
        width='100%'
        className={classes.card}
        footerColor={
          isPrimaryLanguage
            ? theme.palette.success.main
            : isActive
            ? theme.palette.success.main
            : theme.palette.grey[500]
        }
        footerText={isPrimaryLanguage ? 'Primärsprache (immer aktiv)' : isActive ? 'Aktiv' : 'Inaktiv'}
        topRightActionButton={
          !isPrimaryLanguage ? (
            <IconButton
              onClick={handleMenuClick}
              disabled={lockState !== 'canEdit'}
              aria-controls={menuOpen ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={menuOpen ? 'true' : undefined}
            >
              <MoreVert />
            </IconButton>
          ) : null
        }
      >
        <div className={classes.cardContent}>
          {isPrimaryLanguage && (
            <Typography className={classes.centerVertically}>Hauptsprache des Assistenten</Typography>
          )}
          {!isPrimaryLanguage && (
            <div>
              <div id='dialog-texts' className={`${classes.centerVertically} ${classes.flexRow}`}>
                {totalTexts !== translatedTexts ? (
                  <Warning tooltip='Es müssen alle Texte übersetzt sein, um die Sprache zu aktiveren.' />
                ) : (
                  <Check tooltip='Alle Texte sind übersetzt.' />
                )}
                <Typography className={classes.centerVertically}>
                  {`${translatedTexts} von ${totalTexts} Texten übersetzt`}
                </Typography>
              </div>
              <div id='terms' className={`${classes.centerVertically} ${classes.flexRow}`}>
                {totalTechnicalTerms !== translatedTechnicalTerms ? (
                  <Warning tooltip='Es sind nicht alle Texte übersetzt.' />
                ) : (
                  <Check tooltip='Alle Texte sind übersetzt.' />
                )}
                <Typography className={classes.centerVertically}>
                  {`${translatedTechnicalTerms} von ${totalTechnicalTerms} Fachbegriffen übersetzt`}
                </Typography>
              </div>
              {hasNLUKnowledgeDB && (
                <div id='answers' className={`${classes.centerVertically} ${classes.flexRow}`}>
                  {totalKnowledgeTexts !== translatedKnowledgeTexts ? (
                    <Warning tooltip='Es sind nicht alle Texte übersetzt.' />
                  ) : (
                    <Check tooltip='Alle Texte sind übersetzt.' />
                  )}
                  <Typography className={classes.centerVertically}>
                    {`${translatedKnowledgeTexts} von ${totalKnowledgeTexts} Wissensdatenbanktexten übersetzt`}
                  </Typography>
                </div>
              )}
            </div>
          )}
        </div>
      </BaseCard>
      {!isPrimaryLanguage && anchorElementForMenu && (
        <LanguageCardMenu
          open={menuOpen}
          language={languageCode}
          isActive={isActive}
          onChangeActiveState={(): void => {
            handleMenuClose()
            onChangeActiveState(languageCode)
          }}
          onClose={handleMenuClose}
          onDelete={(): void => {
            handleMenuClose()
            onDelete(languageCode)
          }}
          onEdit={(): void => {
            handleMenuClose()
            onClick(languageCode)
          }}
          anchorElement={anchorElementForMenu}
        />
      )}
    </>
  )
}
