import { NodeType } from '../@types/Flowchart/types'
import { CardType } from '../@types/SmartCards/types'

export const CONVAISE_CUSTOMER_ID = 'cd8804a8-a80d-4582-a64d-e1ce63111f02'

// APP TEXT
export const APP_TITLE = 'Convaise Studio'

// Routes
export const ROUTE_LOGIN = '/login'
export const ROUTE_BOTS = '/agents'
export const ROUTE_CREATEBOT = '/new'
export const ROUTE_BOTID = '/:botId'
export const ROUTE_BOTID_DOCUMENTS_PDFINGEST = '/ingest-pdf'
export const ROUTE_BOTID_OVERVIEW = '/overview'
export const ROUTE_BOTID_ANALYTICS = '/analytics'
export const ROUTE_BOTID_DOCUMENTS = '/documents'
export const ROUTE_BOTID_DOCUMENTS_EDIT = '/:documentId'
// export const ROUTE_BOTID_ANALYTICS_INSIGHTS = '/insights'
export const ROUTE_BOTID_ANALYTICS_MODEL = '/qna' // deprecated?
export const ROUTE_BOTID_DESIGNER = '/designer'
export const ROUTE_BOTID_DESIGNER_DIALOGOVERVIEW = '/overview'
export const ROUTE_BOTID_DESIGNER_CREATEDIALOG = '/create-dialog'
export const ROUTE_BOTID_DESIGNER_NODESEARCH = '/search'
export const ROUTE_BOTID_CARDS = '/cards'
export const ROUTE_BOTID_KNOWLEDGE = '/knowledge'
export const ROUTE_BOTID_KNOWLEDGE_MODULE = '/:moduleConfigId'
export const ROUTE_BOTID_TRANSLATIONS = '/translations'
export const ROUTE_BOTID_TRANSLATIONS_DIALOG = '/dialog'
export const ROUTE_BOTID_TRANSLATIONS_ANSWERS = '/answers'
export const ROUTE_BOTID_TRANSLATIONS_TERMS = '/terms'
export const ROUTE_BOTID_TRANSLATIONS_LANG = '/:langCode'
export const ROUTE_BOTID_SETTINGS = '/settings'
export const ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT = '/answers'
export const ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT_MINDMAP = '/map'
export const ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT_LIST = '/list'
export const ROUTE_BOTID_KNOWLEDGE_NLU_CONVMANAGEMENT_EDITANSWER = '/:answerId'
export const ROUTE_BOTID_KNOWLEDGE_NLU_INFOMANAGEMENT = '/infos'
export const ROUTE_BOTID_KNOWLEDGE_NLU_TEMPLATES = '/templates'
export const ROUTE_BOTID_KNOWLEDGE_NLU_MODELMANAGEMENT = '/live-questions'
export const ROUTE_BOTID_KNOWLEDGE_NLU_MODELMANAGEMENT_UNANSWERED = '/unanswered'
export const ROUTE_BOTID_KNOWLEDGE_NLU_MODELMANAGEMENT_ANSWERED = '/answered'
export const ROUTE_BOTID_KNOWLEDGE_RAG_ADD_TXT_DOCUMENT = '/add-txt'
export const ROUTE_BOTID_KNOWLEDGE_RAG_ADD_FILE = '/add-file'
export const ROUTE_BOTID_KNOWLEDGE_RAG_ADD_WEBSITE = '/add-website'
export const ROUTE_BOTID_KNOWLEDGE_RAG_EDIT_DOCUMENT = '/:documentId'
export const ROUTE_HELP = '/help'
export const ROUTE_BOTID_NOTIFICATIONS = '/notifications'
export const ROUTE_BOTID_NOTIFICATIONS_EDITNOTIFICATION = '/:notificationId'

// Links
export const LINK_DATAPROTECTION = 'https://convaise.com/datenschutzhinweise/'
export const LINK_IMPRESSUM = 'https://convaise.com/impressum/'

// E-Mail Adresses
export const EMAIL_SUPPORT = 'support@convaise.com'

// E-Mail subjects
export const EMAIL_SUBJECT_TROUBLELOGIN = '?subject=%5BConvaise%5D%20Trouble-Login'

// Knowledge Management
export const KNOWLEDGE_MAX_ACTIONS = 4
export const KNOWLEDGE_MIN_UTTERANCES = 5
export const KNOWLEDGE_NONE_INTENT = 'None'

// Snackbar Notifications - studio-notification-context
export const SNACKBAR_NOTIFICATIONS_DEFAULT_TIMEOUT = 5000

// Flow Designer
export const FLOWDESIGNER_DATA_STRING = 'application/reactflow'
// grid size
export const FLOWDESIGNER_GRID_SIZE = 20 // grid size in pixel
// node colors per type
export const FLOWDESIGNER_NODE_COLOR_DEFAULT = '#3F6CA6'
export const FLOWDESIGNER_NODE_COLOR_CARD = '#f57b05'
export const FLOWDESIGNER_NODE_COLOR_TRIGGER = '#FAB508'
export const FLOWDESIGNER_NODE_COLOR_QUESTION = '#4caf50'
export const FLOWDESIGNER_NODE_COLOR_API = '#3c4858'
export const FLOWDESIGNER_NODE_COLOR_FILE = '#680094'
export const FLOWDESIGNER_NODE_COLOR_GENERATE = '#CA11F5'
export const FLOWDESIGNER_NODE_COLOR_LOGIC = '#3c4858'
export const FLOWDESIGNER_NODE_COLOR_NOTE = '#616161'
export const FLOWDESIGNER_NODE_COLOR_MODULE = '#4caf50'
// edge color
export const FLOWDESIGNER_EDGE_COLOR = '#727272'
// node size
export const FLOWDESIGNER_NODE_WIDTH = 320 // in pixel
export const FLOWDESIGNER_NODE_MIN_HEIGHT = 100 // in pixel
// port size
export const FLOWDESIGNER_NODE_PORT_DIAMETER = 12 // diameter of a port
export const FLOWDESIGNER_SPACE_PER_PORT = 22 // space taken by a single port, this configures the spacing between ports
// node names
export const NODE_NAMES: Record<NodeType, string> = {
  start: 'Startblock',
  'basic/adaptiveCard': 'Smart Card',
  'basic/api': 'API',
  'basic/message': 'Nachricht',
  'basic/pdf': 'PDF Ausgabe',
  'basic/question_button': 'Frage (Vorschläge)',
  'basic/question_free': 'Frage (Freitext)',
  'basic/picture': 'Bild',
  'basic/fileUpload': 'Datei Upload',
  'logic/ifElse': 'If-Else Logik',
  'logic/switch': 'Switch',
  'logic/switchCondition': 'Switch',
  'logic/jump': 'Springe zu',
  'logic/loop': 'Loop',
  'logic/loopClose': 'Loop Ende',
  'logic/setVar': 'Variable setzen',
  'logic/setVariables': 'Variablen setzen',
  'logic/yesNo': 'Ja/Nein',
  'logic/startDialog': 'Dialog starten',
  'trigger/intent': 'Trigger',
  'trigger/event': 'Event Trigger',
  'trigger/qna': 'QnA Modus Trigger',
  'trigger/analytics': 'Analytics Trigger',
  'logic/qna_answer': 'Frage beantworten',
  'basic/note': 'Notiz',
  'module/xzufi-getAnswer': 'XZuFi Leistung suchen',
  'module/xzufi-getAnswerNew': 'XZuFi Leistung suchen',
  'module/xzufi-trackSelectedAnswer': 'XZuFi Ergebnis tracken',
  'module/xzufi-trackHelpful': 'XZuFi Ergebnis bewerten',
  'module/qna-getAnswer': 'QnA Frage beantworten',
  'module/qna-startTriggerDialog': 'QnA Trigger Dialog starten',
  'module/qna-trackHelpful': 'QnA Antwort bewerten',
  'module/aleph-alpha-getAnswer': 'Aleph Alpha: Frage beantworten',
  'module/aleph-alpha-trackHelpful': 'Aleph Alpha: Ergebnis bewerten',
  'module/llm-task': 'Sprachmodell nutzen',
}

// card types
export const CARD_TYPE_NAMES: Record<CardType, string> = {
  'card/custom': 'Benutzerdefiniert',
  'card/action': 'Aktions-Card',
  'card/input': 'Abfragen-Card',
  'card/content': 'Informations-Card',
}

export const LANGUAGE_DICTIONARY_DE = {
  ar: 'Arabisch',
  bg: 'Bulgarisch',
  bs: 'Bosnisch - Lateinisch',
  cs: 'Tschechisch',
  da: 'Dänisch',
  de: 'Deutsch',
  'de-at': 'Deutsch - Österreich',
  'de-leicht': 'Deutsch - Leichte Sprache',
  el: 'Griechisch',
  en: 'Englisch',
  es: 'Spanish',
  et: 'Estnisch',
  fi: 'Finnisch',
  fr: 'Französisch',
  ga: 'Irisch',
  he: 'Hebräisch',
  hi: 'Hindi',
  hr: 'Kroatisch',
  hu: 'Ungarisch',
  it: 'Italienisch',
  ja: 'Japanisch',
  ko: 'Koreanisch',
  lt: 'Litauisch',
  lv: 'Lettisch',
  nb: 'Norwegisch - Bokmål',
  nl: 'Niederländisch',
  pl: 'Polnisch',
  'pt-pt': 'Portugiesisch - Portugal',
  ro: 'Rumänisch',
  ru: 'Russisch',
  sk: 'Slowakisch',
  sl: 'Slowenisch',
  sq: 'Albanisch',
  sv: 'Schwedisch',
  tr: 'Türkisch',
  uk: 'Ukrainisch',
  ur: 'Urdu',
  'zh-Hans': 'Chinesisch Vereinfacht',
}

// logic operators / comparators
export const LOGIC_OPERATORS = ['==', '!=', '<', '>', '<=', '>='] // used for ifElse node

// Adaptive Cards - Templating - Adaptive Expressions
export const AC_EXPRESSION_REGEX = /\$\{([^}{]*|\$\{([^}{]*|\$\{[^}{]*\})*\})*\}/gm

// CUSTOM NOTIFICATIONS
export const CUSTOM_NOTIFICATION_CARD_HEIGHT = '230px'

// Module Types
export const MODULE_TYPE_NLU = 'nlu' // classic nlu for intent based question answering
export const MODULE_TYPE_RAG = 'rag' // RAG module for question answering
export const MODULE_TYPE_XZUFI = 'xzufi'
export const MODULE_TYPE_ALEPHALPHA = 'aleph-alpha'
export const MODULE_TYPE_LLM = 'llm' // access to llm module
// Module Names
export const MODULE_NAME_CONVAISE_NLU = 'nlu-convaise'
export const MODULE_ID_CONVAISE_NLU = '70113409-683e-47d7-add9-8bb05b1f70e6'
export const MODULE_ID_CONVAISE_LLM_NLU = 'fa83d136-406a-4525-b2d6-c3f75b02f434'
export const MODULE_ID_CONVAISE_RAG = '519155e0-1d17-45b8-bfbd-9a6f926e6e3e'
export const MODULE_NAME_CONVAISE_RAG = 'rag-convaise'
export const MODULE_NAME_ALEPH_ALPHA_CONVERSATIONAL = 'aleph-alpha-lumi-conversational'
export const MODULE_NAME_ALEPH_ALPHA_LUMI = 'aleph-alpha-lumi'
export const MODULE_NAME_XZUFI_REGIOIT_VR = 'regioit-vr'
