import { useInterval } from '../useInterval/useInterval'
import React, { createContext, useContext, useReducer, useEffect, useCallback, useRef, useMemo } from 'react'
// Types
import { LockState, LockScope, LockedBy } from '../../@types/Locking/types'
// Contexts
import { useBotContext } from './bot-context'
// API
import {
  getTranslations as loadTranslationsApi,
  saveTranslationFile as saveTranslationsApi,
  deleteLanguage as deleteLanguageApi,
  addLanguage as addLanguageApi,
  activateLanguage as activateLanguageApi,
  deactivateLanguage as deactivateLanguageApi,
  reloadBot as reloadBotApi,
} from '../../api/StudioBackend'
import * as chartUtils from 'utils/chartUtils'
import { useStudioNotificationContext } from './studio-notification-context'
import { useStudioContext } from './studio-context'
import { AddLanguageResponse, DeleteLanguageResponse, TranslationFile } from '../../@types/Translations/types'
import { BotEnvironment, BotInfos } from '../../@types/BotInformation/types'
import { useErrorContext } from './errorContext'
import { cloneDeep, isEqual } from 'lodash'
import { useLockingContext } from './locking-context'
import { useChartContext } from './chart-context'
import { areAllTextsOfLanguageTranslated } from 'utils/translationFileUtils'
import { useAnswers } from './answers-context'

// ----- TYPE DEFINITIONS -----

// Maps Actions to Payloads - Standard does not need customization
type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

export type Loading =
  | 'loading'
  | 'creating'
  | 'deleting'
  | 'activating'
  | 'deactivating'
  | 'publishing'
  | 'saving'
  | 'success'
  | 'error'
  | undefined

type SetLoadingOptions = { loadingTranslationFile?: Loading }

// Possible Actions
enum Types {
  SetLoading = 'SET_LOADING',
  SetTranslationFile = 'SET_TRANSLATIONFILE',
}

// Payloads for each action
type TranslationsPayload = {
  [Types.SetLoading]: { loadingTranslationFile: Loading }
  [Types.SetTranslationFile]: { translationFile: TranslationFile; flag: 'orig' | 'modified' }
}

type Action = ActionMap<TranslationsPayload>[keyof ActionMap<TranslationsPayload>]
type State = {
  loadingTranslationFile?: Loading
  translationFileOrig?: TranslationFile // translation file as it exists in storage. Updated if the user saves their local changes.
  translationFile?: TranslationFile // local translation file that gets changed as the user works on it.
}

type Dispatch = (action: Action) => void
type TranslationContextProviderProps = { children: React.ReactNode }

/**
 * ----- CONTEXT -----
 * Locking Context is a local context used for managing
 *
 * To access this context:
 * import { useLockingContext } from '<path>/hooks/contexts/locking-context.tsx'
 *
 * // in a function
 * const {lockState, lockedBy} = useLockingContext()
 *
 * setLock()
 */

const TranslationsContext = createContext<
  | {
      state: State
      dispatch: Dispatch
      setLoading: (options: SetLoadingOptions) => void
      setTranslationFile: (
        translationFile: TranslationFile,
        flagForUpdate: 'orig' | 'modified' | 'origAndModified',
      ) => void
    }
  | undefined
>(undefined)

function translationsReducer(state: State, action: Action): State {
  switch (action.type) {
    case Types.SetLoading: {
      const newState = { ...state }
      if (Object.keys(action.payload).includes('loadingTranslationFile')) {
        newState.loadingTranslationFile = action.payload.loadingTranslationFile
      }
      return newState
    }
    case Types.SetTranslationFile: {
      if (action.payload.flag === 'orig') {
        return {
          ...state,
          translationFileOrig: cloneDeep(action.payload.translationFile),
        }
      } else {
        // locally modified translations
        return {
          ...state,
          translationFile: cloneDeep(action.payload.translationFile),
        }
      }
    }
    default: {
      // helps us avoid typos!
      throw new Error(`[Translations-Reducer] Unhandled action type: ${(action as any).type}`)
    }
  }
}

/**
 * Context provider for providing StudioContext
 * @returns
 */
function TranslationsContextProvider({ children }: TranslationContextProviderProps): JSX.Element {
  const { bot } = useBotContext() as { bot: BotInfos } // can cast here because bot has to be set if this provider is used
  const isinitializedRef = useRef<boolean>(false)
  const { sessionId } = useStudioContext()
  const { setError } = useErrorContext()
  // console.log('SessionId: ', sessionId)
  // const [sessionId, setSessionId] = useState<string | null>(null)
  const { setNotification, clearNotification, Notification } = useStudioNotificationContext()
  // init to isBlocked -> we only allow editing if the user really got a lock!
  const [state, dispatch] = useReducer(translationsReducer, {
    loadingTranslationFile: undefined,
  })
  const { loadingTranslationFile } = state

  /**
   * Sets loading state.
   * @param loadingObject {loadingTranslationFile: Loading, etc.} to set specific states.
   */
  function setLoading(loadingOptions: SetLoadingOptions): void {
    if (Object.keys(loadingOptions).includes('loadingTranslationFile')) {
      dispatch({ type: Types.SetLoading, payload: { loadingTranslationFile: loadingOptions.loadingTranslationFile } })
    }
  }

  /**
   * Sets translation file in state.
   * @param translationFile
   */
  function setTranslationFile(
    translationFile: TranslationFile,
    flagForUpdate: 'orig' | 'modified' | 'origAndModified',
  ): void {
    if (flagForUpdate === 'orig' || flagForUpdate === 'origAndModified') {
      dispatch({ type: Types.SetTranslationFile, payload: { translationFile, flag: 'orig' } })
    }
    if (flagForUpdate === 'modified' || flagForUpdate === 'origAndModified') {
      dispatch({ type: Types.SetTranslationFile, payload: { translationFile, flag: 'modified' } })
    }
  }

  /**
   * Fetches translation file from API and sets it in state.
   */
  async function loadTranslationFile(): Promise<void> {
    if (!bot?.id) return
    setLoading({ loadingTranslationFile: 'loading' })
    const translationFile = await loadTranslationsApi(bot.id)
    if (translationFile) {
      setTranslationFile(translationFile, 'origAndModified')
      isinitializedRef.current = true
    } else {
      // set error
      setError(
        'Knowledge.specific.loadAnswersError',
        'Das Wissen des Assistenten konnte nicht geladen werden. Bitte versuchen Sie es erneut.',
        'Erneut versuchen',
        loadTranslationFile,
        'Fehler beim Laden des Wissens',
      )
    }
    setLoading({ loadingTranslationFile: undefined })
  }

  useEffect(() => {
    // initially load translations. We need this to get languages of bot.
    if (!bot || isinitializedRef.current) return
    console.log('provider: Loading translation file')
    loadTranslationFile()
  }, [bot])

  useEffect(() => {
    // reset loading state after time sensitive states
    let timeout
    if (loadingTranslationFile === 'success' || loadingTranslationFile === 'error') {
      timeout = setTimeout(() => {
        setLoading({ loadingTranslationFile: undefined })
      }, 3000)
    }
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [loadingTranslationFile])

  // useEffect(() => {
  //   // reset loading state after time sensitive states
  //   let timeout
  //   if (
  //     loadingTranslationFile === 'savingSuccess' ||
  //     loadingTranslationFile === 'creatingSuccess' ||
  //     loadingTranslationFile === 'publishSuccess' ||
  //     loadingTranslationFile === 'deletingSuccess' ||
  //     loadingTranslationFile === 'savingError' ||
  //     loadingTranslationFile === 'creatingError' ||
  //     loadingTranslationFile === 'publishError' ||
  //     loadingTranslationFile === 'deletingError'
  //   ) {
  //     timeout = setTimeout(() => {
  //       dispatch({ type: Types.SetLoading, payload: { loading: undefined } })
  //     }, 3000)
  //   }
  //   return () => {
  //     if (timeout) clearTimeout(timeout)
  //   }
  // }, [loadingTranslationFile])

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  // const value = useMemo(() => [state, dispatch], [state])

  const value = { state, dispatch, setLoading, setTranslationFile }
  return <TranslationsContext.Provider value={value}>{children}</TranslationsContext.Provider>
}

/**
 * Hook for accessing and manipulating the LockingContext state
 * @returns
 *
 * To access this context:
 * import { useLockingContext } from '<path>/hooks/contexts/locking-context.tsx'
 *
 * // in a function
 * const {lockState, lockedBy} = useLockingContext()
 *
 */
function useTranslationsContext(): {
  loadingTranslationFile: Loading
  translationFile?: TranslationFile
  hasTranslationFileChanged: boolean
  isPublishButtonEnabled: boolean
  // isActivateLanguageButtonEnabled: {
  //   [lang: string]: boolean
  // }
  discardTranslationChanges: () => void
  saveTranslationFile: () => Promise<void> // saves modified translation file to API
  addLanguage: (language: string) => Promise<void>
  deleteLanguage: (language: string) => Promise<void>
  activateLanguage: (language: string) => Promise<void>
  deactivateLanguage: (language: string) => Promise<void>
  publishLanguagesAndTranslations: (deployEnvironment: BotEnvironment) => Promise<void>
  updateTextTranslation: (translationId: string, language: string, newTranslation: string) => void
  updateTextTranslationBatch: (
    translations: { translationId: string; newTranslation: string }[],
    language: string,
  ) => void
} {
  const { bot, setBot } = useBotContext()
  const { setNotification } = useStudioNotificationContext()
  const { lockState } = useLockingContext()
  const { chart } = useChartContext()
  const { reloadAnswersFromAPI } = useAnswers()
  const context = useContext(TranslationsContext)
  if (context === undefined) {
    throw new Error('[useTranslationsContext] useTranslationsContext must be used within a TranslationsContextProvider')
  }

  // splitting it up, allows us to add additional api calls
  const { state, dispatch, setLoading, setTranslationFile } = context
  const { loadingTranslationFile, translationFile, translationFileOrig } = state

  // ---- API ----
  // To not let anything work on the context state directly and to have an easy to use API

  // === LANGUAGE MANAGEMENT ===

  /**
   * Deletes language using the API.
   * Sets updated botinfos and translation file in context / state.
   */
  const deleteLanguage = useCallback(
    async (lang: string): Promise<void> => {
      if (!bot || !lang || lockState !== 'canEdit') return
      setLoading({ loadingTranslationFile: 'deleting' })
      const result = (await deleteLanguageApi(bot.id, lang)) as DeleteLanguageResponse
      if (result === null) {
        // error
        setLoading({ loadingTranslationFile: 'error' })
        setNotification('error', 'Löschen der Sprache fehlgeschlagen.')
      } else {
        // success - we got updated botinfos and translationfile
        const { botInfos, translationFile } = result
        setBot(botInfos)
        setTranslationFile(translationFile, 'origAndModified')
        setLoading({ loadingTranslationFile: 'success' })
        setNotification('success', 'Sprache erfolgreich gelöscht.')
      }
    },
    [bot, lockState],
  )

  /**
   * Creates language using the API.
   * Sets updated botinfos and translation file in context / state.
   */
  const addLanguage = useCallback(
    async (lang: string): Promise<void> => {
      if (!bot || !lang || lockState !== 'canEdit') return
      setLoading({ loadingTranslationFile: 'creating' })
      const result = (await addLanguageApi(bot.id, lang)) as AddLanguageResponse
      if (result === null) {
        // error
        setLoading({ loadingTranslationFile: 'error' })
        setNotification('error', 'Hinzufügen der Sprache fehlgeschlagen.')
      } else {
        // success - we got updated botinfos and translationfile
        // we refetch all answers from the API to ensure that the new language is present everywhere
        await reloadAnswersFromAPI()
        const { botInfos, translationFile } = result
        setBot(botInfos)
        setTranslationFile(translationFile, 'origAndModified')
        setLoading({ loadingTranslationFile: 'success' })
        setNotification('success', 'Sprache erfolgreich hinzugefügt.')
      }
    },
    [bot, lockState],
  )

  /**
   * Activates language using the API.
   * Sets updated botinfos and translation file in context / state.
   */
  const activateLanguage = useCallback(
    async (lang: string): Promise<void> => {
      if (!bot || !lang || lockState !== 'canEdit' || !translationFile) return
      setLoading({ loadingTranslationFile: 'activating' })
      const updatedBotInfos = await activateLanguageApi(bot.id, lang)
      if (updatedBotInfos === null) {
        // error
        console.log('Activation error')
        setLoading({ loadingTranslationFile: 'error' })
        setNotification('error', 'Aktivieren der Sprache fehlgeschlagen.')
      } else {
        // success - we got updated botinfos and translationfile
        setLoading({ loadingTranslationFile: 'success' })
        setBot(updatedBotInfos)
        translationFile.publishedLanguages.push(lang)
        console.log('Activation')
        setTranslationFile(translationFile, 'origAndModified')
        setNotification('success', 'Sprache erfolgreich aktiviert.')
      }
    },
    [bot, translationFile, lockState],
  )

  /**
   * Deactivates language using the API.
   * Sets updated botinfos and translation file in context / state.
   */
  const deactivateLanguage = useCallback(
    async (lang: string): Promise<void> => {
      if (!bot || !lang || lockState !== 'canEdit' || !translationFile) return
      setLoading({ loadingTranslationFile: 'deactivating' })
      const updatedBotInfos = await deactivateLanguageApi(bot.id, lang)
      if (updatedBotInfos === null) {
        // error
        setLoading({ loadingTranslationFile: 'error' })
        setNotification('error', 'Deaktivieren der Sprache fehlgeschlagen.')
      } else {
        // success - we got updated botinfos and translationfile
        setLoading({ loadingTranslationFile: 'success' })
        translationFile.publishedLanguages = translationFile.publishedLanguages.filter((langCode) => langCode !== lang)
        setTranslationFile(translationFile, 'origAndModified')
        setBot(updatedBotInfos)
        setNotification('success', 'Sprache erfolgreich deaktiviert.')
      }
    },
    [bot, translationFile, lockState],
  )

  /**
   * Publishes languages to bot. Makes them available for all users.
   */
  const publishLanguagesAndTranslations = useCallback(
    async (deployEnvironment: BotEnvironment): Promise<void> => {
      if (!bot || !deployEnvironment || lockState !== 'canEdit' || !translationFile) return
      setLoading({ loadingTranslationFile: 'publishing' })
      const response = await reloadBotApi(bot.id, deployEnvironment, true, true)
      if (response) {
        // success
        console.log('Published')
        setLoading({ loadingTranslationFile: 'success' })
        setNotification('success', 'Sprachen erfolgreich veröffentlicht.')
      } else {
        setLoading({ loadingTranslationFile: 'error' })
        setNotification('error', 'Veröffentlichen der Sprache fehlgeschlagen.')
      }
    },
    [bot, translationFile, lockState],
  )

  // === TRANSLATIONS MANAGEMENT ===

  const updateTextTranslation = useCallback(
    (translationId: string, language: string, newTranslation: string): void => {
      if (!chart || !translationFile) return

      if (!translationFile?.translations?.texts || !translationFile.translations?.texts[translationId]) return

      // replace variable display name with variable id
      // this is important for the bot
      const textWithoutVariableName = chartUtils.replaceVarDisplayNameWithId(chart, newTranslation)
      translationFile.translations.texts[translationId][language] = textWithoutVariableName
      // update modified file in context
      setTranslationFile(translationFile, 'modified')
    },
    [translationFile, chart],
  )

  const updateTextTranslationBatch = useCallback(
    (translations: { translationId: string; newTranslation: string }[], language: string): void => {
      if (!chart || !translationFile || !translationFile?.translations?.texts) return

      for (const translation of translations) {
        if (!translationFile.translations?.texts[translation.translationId]) continue
        // replace variable display name with variable id
        // this is important for the bot
        const textWithoutVariableName = chartUtils.replaceVarDisplayNameWithId(chart, translation.newTranslation)
        translationFile.translations.texts[translation.translationId][language] = textWithoutVariableName
      }

      // update modified file in context
      setTranslationFile(translationFile, 'modified')
    },
    [translationFile, chart],
  )

  /**
   * Saves modified translation file via Studio API.
   * Sets translationfile received in response as orig and modified file into the context.
   */
  const saveTranslationFile = useCallback(async (): Promise<void> => {
    if (!bot || !translationFile || lockState !== 'canEdit') return
    setLoading({ loadingTranslationFile: 'saving' })
    try {
      const response = await saveTranslationsApi(bot.id, translationFile)
      if (!response) throw new Error('Save translations response is null')
      const { translations: updatedTranslationFile, botInfos: updatedBotInfos } = response

      // set updated botinfos (with new translation file version)
      setBot(updatedBotInfos)
      // set updated translations as both modified and orig
      setTranslationFile(updatedTranslationFile, 'origAndModified')
      setNotification('success', 'Übersetzungen wurden gespeichert.')
      setLoading({ loadingTranslationFile: 'success' })
    } catch (err) {
      // error saving
      setNotification('error', 'Übersetzungen konnten nicht gespeichert werden.')
      setLoading({ loadingTranslationFile: 'error' })
    }
  }, [translationFile, translationFileOrig, lockState])

  /**
   * Discards all unsaved changes by overwriting the translationfile with the original translation file.
   */
  const discardTranslationChanges = useCallback((): void => {
    if (translationFileOrig) setTranslationFile(translationFileOrig, 'modified')
  }, [translationFileOrig])

  const hasTranslationFileChanged = useMemo(() => {
    return !isEqual(translationFile, translationFileOrig)
  }, [translationFile, translationFileOrig])

  /**
   * Checks if user is allowed to publish.
   * Button is enabled if:
   * - all dialog texts of activated languages are translated
   * - all answers of activated languages are translated.
   */
  const isPublishButtonEnabled = useMemo(() => {
    if (!translationFile) return false

    for (const lang of translationFile.publishedLanguages) {
      // TODO: check answers as well
      if (!areAllTextsOfLanguageTranslated(translationFile, lang)?.complete) return false
    }

    return true
  }, [translationFile, translationFileOrig])

  // /**
  //  * Indicates if language activation is possible. It is possible if
  //  * - all dialog texts of that language are translated
  //  * - all answers of that language are translated
  //  * -
  //  */
  // const isActivateLanguageButtonEnabled = useMemo((): { [lang: string]: boolean } => {
  //   const result: { [lang: string]: boolean } = {}
  //   if (!translationFile) return {}
  //   for (const lang of translationFile?.languages ?? []) {
  //     if (lang === translationFile?.primaryLanguage) continue

  //     result[lang] = !!areAllTextsOfLanguageTranslated(translationFile, lang)?.complete

  //     // TODO: check answers
  //   }
  //   return result
  // }, [translationFile])

  // Controll what is returned
  return {
    loadingTranslationFile,
    translationFile,
    hasTranslationFileChanged,
    isPublishButtonEnabled,
    // isActivateLanguageButtonEnabled,
    discardTranslationChanges,
    saveTranslationFile,
    addLanguage,
    deleteLanguage,
    activateLanguage,
    deactivateLanguage,
    publishLanguagesAndTranslations,
    updateTextTranslation,
    updateTextTranslationBatch,
  }
}

export { TranslationsContextProvider, useTranslationsContext }
