/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, RefObject } from 'react'
import { TextField as MaterialTextField } from '@mui/material'

type TextfieldProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (event: any) => void
  onBlur?: () => void
  onClick?: () => void
  className?: string
  autoFocus?: boolean
  disabled?: boolean
  error?: boolean
  placeholder?: string
  label?: string
  helperText?: string | React.ReactNode
  focused?: boolean
  value?: unknown
  defaultValue?: unknown
  id?: string
  type?: string
  rows?: number | string
  rowsMax?: number | string
  rowsMin?: number | string
  multiline?: boolean
  ref?: RefObject<HTMLInputElement>
  endButton?: React.ReactNode
  fullWidth?: boolean
  readOnly?: boolean
  onKeyUp?: React.KeyboardEventHandler<HTMLDivElement>
  // these two input props are currently only needed for Dropdown
  InputProps?: any
  inputProps?: any
}

export const Textfield = ({
  onChange,
  onBlur,
  onClick,
  focused,
  autoFocus,
  className,
  disabled,
  error,
  placeholder,
  label,
  helperText,
  value,
  defaultValue,
  id,
  type,
  rows,
  rowsMax,
  rowsMin,
  ref,
  multiline,
  fullWidth,
  endButton,
  readOnly,
  onKeyUp,
  InputProps,
  inputProps,
}: TextfieldProps): ReactElement => {
  // remove css style from input props provided as params in order to not overwrite textfield style
  if (InputProps) delete InputProps.className
  if (inputProps) delete inputProps.className

  return (
    <MaterialTextField
      className={className}
      InputProps={{
        // className: classes.input,
        // disableUnderline: true,
        endAdornment: endButton,
        readOnly: readOnly,
        ...InputProps,
      }}
      inputProps={{
        // className: classes.inputProps,
        ...inputProps,
      }}
      focused={focused}
      onClick={onClick}
      onChange={onChange}
      onBlur={onBlur}
      autoFocus={autoFocus}
      disabled={disabled}
      error={error}
      placeholder={placeholder}
      value={value}
      defaultValue={defaultValue}
      onKeyUp={onKeyUp}
      type={type}
      rows={rowsMax || rowsMin ? undefined : rows}
      maxRows={rowsMax}
      minRows={rowsMin}
      multiline={multiline}
      id={id}
      label={label}
      helperText={helperText}
      ref={ref}
      fullWidth={fullWidth}
    />
  )
}

Textfield.defaultProps = {
  value: '',
}
