import { TranslationFile } from '../@types/Translations/types'
import { cloneDeep } from 'lodash'
import { shouldTranslationBeDisplayed } from './translationsUtils'

/**
 * Checks if all texts of a language are translated.
 * Returns boolean flag and number of texts and translated texts.
 * @param translationFile
 * @param language
 */
export function areAllTextsOfLanguageTranslated(
  translationFile: TranslationFile,
  language: string,
): { complete: boolean; totalTexts: number; translatedTexts: number } | null {
  if (!translationFile?.translations?.texts) return null
  const allTextIds = Object.keys(translationFile.translations.texts).filter((transId) => {
    if (translationFile.translations.texts && translationFile.translations.texts[transId]) {
      return !!translationFile.translations.texts[transId][translationFile.primaryLanguage]
    }
    return false
  })
  const allDisplayedTextIds = allTextIds.filter((textId) => {
    if (translationFile.translations.texts)
      return shouldTranslationBeDisplayed(translationFile.translations.texts[textId][translationFile.primaryLanguage])
    return false
  })
  const totalTexts = allDisplayedTextIds.length
  // count translated texts
  let translatedTexts = 0
  for (const textId of allDisplayedTextIds) {
    if (translationFile.translations.texts[textId][language]) translatedTexts += 1
  }

  return {
    complete: totalTexts === translatedTexts,
    totalTexts,
    translatedTexts,
  }
}

/**
 * Deletes a smartcard from the translation file.
 * @param translations
 * @param cardId
 */
export function deleteSmartCard(translations: TranslationFile, cardId: string): TranslationFile {
  const newTranslations = cloneDeep(translations)
  for (const lang of Object.keys(translations.ac)) {
    delete newTranslations.ac[lang][cardId]
  }

  delete newTranslations.acTranslations[cardId]

  return newTranslations
}
