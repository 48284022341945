import { Check } from '@mui/icons-material'
import { IconButton, Typography, useTheme } from '@mui/material'
import { Button } from 'components/Buttons'
import { Dialog } from 'components/Dialogs'
import { Textfield } from 'components/TextInput/Textfield'
import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import RMEEditor from 'components/TextEditors/editor-mdx-markdown'
import ReactMarkdown from 'react-markdown'

// Component Styles
const useStyles = makeStyles()((theme) => ({
  showContentButton: { marginLeft: 'auto' },

  contentPreviewWrapper: {
    width: '100%',
    height: '200px',
    border: `0.5px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius * 2,
    position: 'relative',
    cursor: 'zoom-in',
    overflow: 'hidden',
  },
  contentPreviewContainer: {
    height: '100%',
    overflow: 'auto',
    padding: theme.spacing(2),
    zIndex: 0,
  },
  fadeEffect: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0, // Account for scrollbar
    height: '60px',
    background: 'linear-gradient(to bottom, transparent, rgba(255, 255, 255, 1))',
    pointerEvents: 'none',
    zIndex: 1,
  },
}))

type ContentProps = {
  descriptionText: React.ReactNode
  content: string
  onContentChange: (content: string) => void
}

export default function Content({ descriptionText, content, onContentChange }: ContentProps): React.ReactElement {
  const theme = useTheme()
  const { classes } = useStyles()

  const [displayContent, setDisplayContent] = useState<boolean>(false)

  function hideDialog(): void {
    setDisplayContent(false)
  }

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: theme.spacing(2) }}>
        {descriptionText}
        <IconButton
          onClick={(e: React.MouseEvent): void => {
            setDisplayContent(true)
          }}
          className={classes.showContentButton}
        >
          <i className='ri-eye-line'></i>
        </IconButton>
      </div>

      <div onClick={() => setDisplayContent(true)} className={classes.contentPreviewWrapper}>
        <div className={classes.fadeEffect}></div>
        <div className={classes.contentPreviewContainer}>
          <ReactMarkdown>{content}</ReactMarkdown>
        </div>
      </div>

      <Dialog
        id='edit-content-dialog'
        title='Inhalt bearbeiten'
        size='extra-large'
        open={displayContent}
        secondaryActionText='Abbrechen'
        onSecondaryActionClick={() => hideDialog()}
        primaryActionButton={
          <Button
            type='success'
            // icon={<Check />}
            onClick={() => {
              onContentChange(content)
              hideDialog()
            }}
            disabled={!content}
          >
            Übernehmen
          </Button>
        }
        onClose={() => {
          hideDialog()
        }}
      >
        <RMEEditor
          // defaultInput={answerText || ''}
          input={content || ''}
          onChangeCallback={(markdown): void => {
            onContentChange(markdown)
          }}
          placeholder='Websiteinhalt'
        />
      </Dialog>
    </div>
  )
}
