import { FormControlLabel, FormGroup, Switch, Typography } from '@mui/material'
import { Textfield } from 'components/TextInput/Textfield'
import { useBotContext } from 'hooks/contexts/bot-context'
import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { BotSettings } from '../../@types/Settings/types'
import { getAccount } from 'components/AuthProvider/PublicClientApp'
import { CONVAISE_CUSTOMER_ID } from 'utils/constants'
import { useLockingContext } from 'hooks/contexts/locking-context'

type Colors = {
  primaryColor?: string
  primaryColorText?: string
  disabledColor?: string
}

const useStyles = makeStyles()((theme) => ({
  content: {},
  buttons: { display: 'flex' },
  button: { width: '180px', margin: `0 ${theme.spacing(1)}` },
  heading: { margin: `${theme.spacing(2)} 0`, marginBottom: theme.spacing(3) },
  row: { display: 'flex' },
  keyTypography: { margin: 'auto 0' },
  generalContainer: { marginTop: theme.spacing(5) },
  stylesContainerContent: { display: 'flex', flexDirection: 'column' },
  avatarContainer: { marginTop: theme.spacing(2), width: '100%', marginBottom: theme.spacing(2) },
  settingWrapperWithMargin: { marginTop: theme.spacing(2) },
  colorsContainer: {
    maxWidht: '200px',
    maxHeight: '200px',
  },
  avatarUpload: {
    height: '200px',
    width: '200px',
    border: '3px dotted grey',
    display: 'flex',
  },
  avatarImage: {
    width: '100%',
    height: 'fit-content',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  colorPreview: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: theme.spacing(1),
    width: '40px',
    height: '40px',
    borderRadius: '50%',
  },
}))

type QnASettingsProps = {
  webchatSettings: BotSettings['webchatSettings']
  qnaSettings: BotSettings['qnaSettings']
  onWebchatSettingsChange: (newWebchatSettings: BotSettings['webchatSettings']) => void
  onQnaSettingsChange: (newQnaSettings: BotSettings['qnaSettings']) => void
}

export default function QnASettings({
  webchatSettings,
  qnaSettings,
  onWebchatSettingsChange,
  onQnaSettingsChange,
}: QnASettingsProps): React.ReactElement {
  const { classes } = useStyles()
  const { bot } = useBotContext()
  const { lockState } = useLockingContext()
  const account = getAccount()
  const customerIdOfCurrentUser = account?.idTokenClaims ? account?.idTokenClaims['extension_CustomerID'] : undefined

  // qna mode texts
  const [qnaTextInputPlaceholder, setQnaTextInputPlaceholder] = useState<string>()
  const [qnaModeWelcomeText, setQnAmodeWelcomeText] = useState<string>()
  const [qnaModeAskForNewQuestionText, setQnaModeAskForNewQuestionText] = useState<string>()
  // qna settings
  const [qnaModeAskForNewQuestions, setQnaModeAskForNewQuestions] = useState<boolean>()
  const [rewriteAnswerToFitQuestion, setRewriteAnswerToFitQuestion] = useState<boolean>()

  function onQnaTextInputPlaceholderChange(event: React.ChangeEvent<HTMLInputElement>) {
    setQnaTextInputPlaceholder(event.target.value)
    const newWebchatTexts = { ...(webchatSettings?.webchatTexts ?? {}), qnaTextInputPlaceholder: event.target.value }
    onWebchatSettingsChange({ ...webchatSettings, webchatTexts: newWebchatTexts })
  }

  function onQnaModeWelcomeTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    setQnAmodeWelcomeText(event.target.value)
    const newWebchatTexts = { ...(webchatSettings?.webchatTexts ?? {}), qnaModeWelcomeText: event.target.value }
    onWebchatSettingsChange({ ...webchatSettings, webchatTexts: newWebchatTexts })
  }

  function onQnaModeAskForNewQuestionTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    setQnaModeAskForNewQuestionText(event.target.value)
    const newWebchatTexts = {
      ...(webchatSettings?.webchatTexts ?? {}),
      qnaModeAskForNewQuestionText: event.target.value,
    }
    onWebchatSettingsChange({ ...webchatSettings, webchatTexts: newWebchatTexts })
  }

  function onQnaModeAskForNewQuestionsChange(event: React.ChangeEvent<HTMLInputElement>) {
    setQnaModeAskForNewQuestions(event.target.checked)
    onQnaSettingsChange({ ...qnaSettings, qnaModeAskForNewQuestion: event.target.checked })
  }

  function onRewriteAnswerToFitQuestionChange(event: React.ChangeEvent<HTMLInputElement>) {
    setRewriteAnswerToFitQuestion(event.target.checked)
    onQnaSettingsChange({ ...qnaSettings, rewriteAnswerToFitQuestion: event.target.checked })
  }

  useEffect(() => {
    if (webchatSettings) {
      if (webchatSettings.webchatTexts) {
        if (qnaTextInputPlaceholder !== webchatSettings.webchatTexts.qnaTextInputPlaceholder)
          setQnaTextInputPlaceholder(webchatSettings.webchatTexts.qnaTextInputPlaceholder)
        if (qnaModeWelcomeText !== webchatSettings.webchatTexts.qnaModeWelcomeText)
          setQnAmodeWelcomeText(webchatSettings.webchatTexts.qnaModeWelcomeText)
        if (qnaModeAskForNewQuestionText !== webchatSettings.webchatTexts.qnaModeAskForNewQuestionText)
          setQnaModeAskForNewQuestionText(webchatSettings.webchatTexts.qnaModeAskForNewQuestionText)
      }
    }

    if (qnaSettings) {
      if (qnaModeAskForNewQuestions !== qnaSettings.qnaModeAskForNewQuestion)
        setQnaModeAskForNewQuestions(qnaSettings.qnaModeAskForNewQuestion)
      if (rewriteAnswerToFitQuestion !== qnaSettings.rewriteAnswerToFitQuestion)
        setRewriteAnswerToFitQuestion(qnaSettings.rewriteAnswerToFitQuestion)
    }
  }, [webchatSettings])

  const IS_CURRENT_USER_CONVAISE_ADMIN = customerIdOfCurrentUser === CONVAISE_CUSTOMER_ID

  return (
    <div id='general-settings' className={classes.generalContainer}>
      <Typography className={classes.heading} variant='h2'>
        QnA Einstellungen
      </Typography>
      <div className={classes.content}>
        <div className={classes.settingWrapperWithMargin}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={rewriteAnswerToFitQuestion}
                  onChange={onRewriteAnswerToFitQuestionChange}
                  disabled={true}
                />
              }
              label='Antworten an Frage anpassen (bald verfügbar)'
              disabled={lockState !== 'canEdit' || true}
            />
            {IS_CURRENT_USER_CONVAISE_ADMIN && (
              <FormControlLabel
                control={<Switch checked={qnaModeAskForNewQuestions} onChange={onQnaModeAskForNewQuestionsChange} />}
                label='Nach neuer Frage fragen'
                disabled={lockState !== 'canEdit'}
              />
            )}
          </FormGroup>
        </div>
        {bot?.webchatVersion === 'v4' && qnaModeAskForNewQuestions && (
          <div className={classes.settingWrapperWithMargin}>
            <Textfield
              label='Text zum Anfordern einer neuen Frage'
              placeholder='Welche Frage kann ich noch beantworten?'
              value={qnaModeAskForNewQuestionText}
              onChange={onQnaModeAskForNewQuestionTextChange}
              fullWidth
              disabled={lockState !== 'canEdit'}
            />
          </div>
        )}

        {bot?.webchatVersion === 'v4' && (
          <>
            <div className={classes.settingWrapperWithMargin}>
              <Textfield
                label='Willkommensnachricht im QnA Modus'
                value={qnaModeWelcomeText}
                onChange={onQnaModeWelcomeTextChange}
                placeholder='Welche Frage kann ich beantworten?'
                fullWidth
                disabled={lockState !== 'canEdit'}
              />
            </div>
            <div className={classes.settingWrapperWithMargin}>
              <Textfield
                label='QnA Textinput Platzhalter'
                value={qnaTextInputPlaceholder}
                onChange={onQnaTextInputPlaceholderChange}
                placeholder='Ihre Frage...'
                fullWidth
                disabled={lockState !== 'canEdit'}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
