import { useInterval } from '../useInterval/useInterval'
import React, { createContext, useContext, useReducer, useEffect, useRef } from 'react'
// Types
import { LockState, LockScope, LockedBy, RequestLockSuccess, RequestLockDenied } from '../../@types/Locking/types'
// Contexts
import { useBotContext } from './bot-context'
// API
import { requestLocks as requestLocksApi, releaseLocks as releaseLocksApi } from '../../api/StudioBackend'
import { useStudioNotificationContext } from './studio-notification-context'
import { useStudioContext } from './studio-context'

// ----- TYPE DEFINITIONS -----

// Maps Actions to Payloads - Standard does not need customization
type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

// Possible Actions
enum Types {
  // SET_LOCK = 'LOCK',
  SET_LOCKS = 'LOCKS',
  SET_ALL_LOCKED = 'SET_ALL_LOCKED',
  RELEASE_LOCK = 'RELEASE_LOCK',
  RESET = 'RESET',
}

// Payloads for each action
type LockingPayload = {
  // [Types.SET_LOCK]: {
  //   lockScope: LockScope
  //   lockState: LockState
  //   lockedBy?: LockedBy
  //   lockInitialized?: boolean
  //   alreadyHadLockBefore?: boolean
  // }
  [Types.SET_LOCKS]: { [lockScope: string]: RequestLockDenied | RequestLockSuccess }
  [Types.SET_ALL_LOCKED]: { scopes: LockScope[] }
  [Types.RESET]: { scopes: LockScope[] }
}

type Action = ActionMap<LockingPayload>[keyof ActionMap<LockingPayload>]
type State = {
  locks: {
    [lockScope: string]: {
      lockState: LockState
      lockedBy?: LockedBy
      lockInitialized?: boolean // true if first requestLock call has been made (this can be used to e.g. determine if locked notification should be shown. On
      // initial load before first request state is locked, but notification should not be shown)
      alreadyHadLockBefore?: boolean
    }
  }
}

type Dispatch = (action: Action) => void
type LockingProviderProps = { children: React.ReactNode; lockScopes: LockScope[] }

/**
 * ----- CONTEXT -----
 * Locking Context is a local context used for managing
 *
 * To access this context:
 * import { useLockingContext } from '<path>/hooks/contexts/locking-context.tsx'
 *
 * // in a function
 * const {lockState, lockedBy} = useLockingContext()
 *
 * setLock()
 */

const LockingContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined)

function lockingReducer(state: State, action: Action): State {
  switch (action.type) {
    case Types.SET_LOCKS: {
      // make sure to spread that state just in case!
      const newLocksState: State['locks'] = {}
      for (const scope of Object.keys(action.payload)) {
        newLocksState[scope] = {
          ...action.payload[scope],
          lockState: action.payload[scope].status === 'success' ? 'canEdit' : 'isBlocked',
        }
      }
      const newState = { ...state, locks: { ...state.locks, ...newLocksState } }
      return newState
    }
    case Types.SET_ALL_LOCKED: {
      if (action.payload.scopes) {
        return {
          ...state,
          locks: action.payload.scopes.reduce(
            (acc, scope): State['locks'] => ({
              ...acc,
              [scope]: {
                lockState: 'isBlocked',
                lockInitialized: true,
              },
            }),
            {},
          ),
        }
      } else {
        return state
      }
    }
    case Types.RESET: {
      if (action.payload.scopes) {
        return {
          ...state,
          locks: action.payload.scopes.reduce(
            (acc, scope): State['locks'] => ({
              ...acc,
              [scope]: {
                lockState: 'isBlocked',
                lockInitialized: false,
              },
            }),
            {},
          ),
        }
      } else {
        return state
      }
    }
    default: {
      // helps us avoid typos!
      throw new Error(`[Locking-Reducer] Unhandled action type: ${(action as any).type}`)
    }
  }
}

/**
 * Context provider for providing StudioContext
 * @returns
 */
function LockingContextProvider({ children, lockScopes }: LockingProviderProps): JSX.Element {
  const { bot } = useBotContext()
  const { sessionId } = useStudioContext()
  const { setNotification, clearNotification, Notification } = useStudioNotificationContext()
  const isInitializedRef = useRef<boolean>(false)
  // init to isBlocked -> we only allow editing if the user really got a lock!
  const [state, dispatch] = useReducer(lockingReducer, {
    locks: lockScopes.reduce(
      (acc, scope): State['locks'] => ({
        ...acc,
        [scope]: {
          lockState: 'isBlocked',
          lockInitialized: false,
        },
      }),
      {},
    ),
  })

  /**
   * Requests lock for editing and sets lock state into state.
   */
  async function requestLocks(): Promise<void> {
    if (!bot) return
    console.info('Locking context: Request lock.')
    try {
      const lockResult = await requestLocksApi(bot.id, lockScopes, sessionId)
      console.log('Lock Result: ', lockResult)
      if (lockResult) {
        // got result, now set locks for each scope if lock was successful
        dispatch({
          type: Types.SET_LOCKS,
          payload: lockResult as { [lockScope: string]: RequestLockDenied | RequestLockSuccess },
        })

        // if one of them is blocked, show notifiation
        let showNotification = false
        let lockedBy
        for (const scope of Object.keys(lockResult)) {
          if (lockResult[scope].status !== 'success') {
            showNotification = true
            lockedBy = lockResult[scope].lockedBy
            break
          }
        }

        if (showNotification) {
          setNotification(
            'warning',
            `Ein anderer Nutzer ${
              lockedBy ? '(' + lockedBy + ')' : ''
            } arbeitet gerade auf dieser Seite. Bitte warten Sie bis dieser Nutzer die Bearbeitung abgeschlossen hat.`,
            undefined,
            false,
          )
        }
        if (!showNotification) {
          clearNotification()
        }
      } else {
        // request failed; set all locks to locked
        throw new Error('requestLock failed.')
      }
    } catch (err) {
      console.error('[Lock] Could not request lock. Locking editing...')
      dispatch({
        type: Types.SET_ALL_LOCKED,
        payload: { scopes: lockScopes },
      })
      if (!Notification) {
        setNotification(
          'warning',
          `Ein anderer Nutzer arbeitet gerade auf dieser Seite. Bitte warten Sie bis dieser Nutzer die Bearbeitung abgeschlossen hat.`,
          undefined,
          false,
        )
      }
    }
  }

  /**
   * Releases lock so that others can work.
   */
  async function releaseLocks(): Promise<void> {
    if (!bot) return
    console.info('Locking context: Releasing lock')
    await releaseLocksApi(bot.id, lockScopes, sessionId)
  }

  /**
   * Resets all locks to initial state.
   */
  function reset(): void {
    dispatch({ type: Types.RESET, payload: { scopes: lockScopes } })
  }

  useInterval(function renewLock() {
    // renew the lock every 60 seconds
    if (bot !== null) requestLocks()
  }, 60 * 1000)

  useEffect(function releaseAndCleanup() {
    // wrapper function so that we can remove event listener again on unmount.
    function releaseLocksOnUnload() {
      releaseLocks()
    }

    // we use event listener to release locks if the tab is closed / refreshed
    window.addEventListener('beforeunload', releaseLocksOnUnload)

    return (): void => {
      window.removeEventListener('beforeunload', releaseLocksOnUnload)
      if (isInitializedRef.current) {
        // console.log('LOCKING CONTEXT PROVIDER UNMOUN´T')
        releaseLocks()
        clearNotification()
        isInitializedRef.current = false
      }
      // reset()
    }
  }, [])

  // useEffect(
  //   function () {
  //     if (sessionId === null) {
  //       const sId = uuid()
  //       setSessionId(sId)
  //     }

  //     return (): void => {
  //       console.log('Cleanup.')
  //       if (sessionId !== null) {
  //         console.log('Cleanup lock.')
  //         releaseLock(sessionId)
  //       }
  //     }
  //   },
  //   [sessionId]
  // )

  useEffect(
    function () {
      if (bot === null || isInitializedRef.current) return
      requestLocks()
      isInitializedRef.current = true
    },
    [bot],
  )

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  // const value = useMemo(() => [state, dispatch], [state])
  const value = { state, dispatch }
  return <LockingContext.Provider value={value}>{children}</LockingContext.Provider>
}

/**
 * Hook for accessing and manipulating the LockingContext state
 * @returns
 *
 * To access this context:
 * import { useLockingContext } from '<path>/hooks/contexts/locking-context.tsx'
 *
 * // in a function
 * const {lockState, lockedBy} = useLockingContext('knowledgeNlu')
 *
 * If no scope is provided, lockState is determined by checking all locks. If any one is locked, state is locked.
 */
function useLockingContext(scope?: LockScope): State['locks'][string] {
  const context = useContext(LockingContext)
  if (context === undefined) {
    throw new Error('[useLockingContext] useLockingContext must be used within a LockingContextProvider')
  }

  // splitting it up, allows us to add additional api calls
  const { state, dispatch } = context

  // ---- API ----
  // To not let anything work on the context state directly and to have an easy to use API

  // State
  const { locks } = state

  let lockState: LockState, lockedBy, lockInitialized, alreadyHadLockBefore
  if (scope) {
    // Extract properties directly using destructuring for the specific scope
    ;({ lockState, lockedBy, lockInitialized, alreadyHadLockBefore } = locks[scope])
  } else {
    // Work with all locks
    const lockValues = Object.values(locks)

    // Set 'lockState' to 'isBlocked' if any lock has the 'lockState' of 'isBlocked'
    lockState = lockValues.some((lock) => lock.lockState === 'isBlocked') ? 'isBlocked' : 'canEdit'

    // Determine if any lock has been initialized
    lockInitialized = lockValues.some((lock) => lock.lockInitialized)

    // Find the first lock that has a 'lockedBy' value
    const lockWithLockedBy = lockValues.find((lock) => lock.lockedBy)
    lockedBy = lockWithLockedBy ? lockWithLockedBy.lockedBy : undefined // set 'lockedBy' to the found value or keep it as 'undefined' if not found
  }

  // Controll what is returned
  return { lockState, lockedBy, lockInitialized, alreadyHadLockBefore }
}

export { LockingContextProvider, useLockingContext }
