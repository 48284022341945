import React, { useEffect, useState } from 'react'

// import { ActionMeta, ValueType } from 'react-select'
import { makeStyles } from 'tss-react/mui'

import BaseDropdown, { Option, ActionMeta, ValueType } from './BaseDropdown'

export type { Option, ActionMeta }

type SelectDropdownStyleProps = {
  height?: string
  width?: string
}

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
const useStyles = makeStyles<SelectDropdownStyleProps>()((theme, props) => ({
  container: {
    width: props?.width,
    height: props.height,
  },
}))

type SelectDropdownProps = {
  selected?: Option | string
  options: Option[]
  onChange: (newValue: Option, action: ActionMeta<Option>) => void
  isDisabled?: boolean
  isSearchable?: boolean
  isCreatable?: boolean
  isClearable?: boolean
  createOptionText?: (text: string) => string
  noOptionsMessage?: string
  placeholder?: string
  width?: string
  height?: string
  maxDisplayOptions?: number
  allowedChars?: string
  forbiddenLabels?: string[] // strings defined here cannot be used when creating a new option
  className?: string
  sublabelFontSize?: string
  sublabelType?: 'text' | 'markdown'
  allowLargeOptions?: boolean // if true, individual items in menu can be larger and menu list has bigger height
}

/**
 * USES BASE DROPDOWN -> ONLY ALLOWS SINGLE SELECT
 * @returns
 */
export default function SelectDropdown({
  options,
  selected: selectedOption,
  onChange,
  isSearchable,
  isDisabled,
  isClearable = false,
  isCreatable = false,
  createOptionText = (text: string): string => `"${text}" erstellen`,
  noOptionsMessage,
  placeholder = 'Bitte auswählen',
  width,
  height,
  maxDisplayOptions,
  allowedChars,
  forbiddenLabels,
  className,
  sublabelFontSize,
  sublabelType,
  allowLargeOptions,
}: SelectDropdownProps): React.ReactElement {
  const { classes } = useStyles({ width, height })

  const [selected, setSelected] = useState<Option>()

  function onChangeIntercept(
    newValue: ValueType<Option, boolean> | ValueType<Option, boolean>[],
    action: ActionMeta<Option>,
  ): void {
    if (!Array.isArray(newValue)) onChange(newValue as Option, action)
    else if (newValue.length > 0) onChange(newValue[0] as Option, action)
  }

  useEffect(
    function () {
      let option = selectedOption
      if (typeof selectedOption === 'string') {
        // find option
        option = options.find((option) => option.value === selectedOption)
      }
      setSelected(option as Option | undefined)
    },
    [selectedOption],
  )

  return (
    <div className={classes.container}>
      <BaseDropdown
        className={className}
        isMulti={false}
        isClearable={isClearable}
        isSearchable={isSearchable}
        isCreatable={isCreatable}
        createOptionText={createOptionText}
        isDisabled={isDisabled}
        selectedOptions={selected}
        options={options}
        onChange={onChangeIntercept}
        placeholder={placeholder}
        noOptionsMessage={noOptionsMessage}
        maxDisplayOptions={maxDisplayOptions}
        height={height}
        allowedChars={allowedChars}
        forbiddenLabels={forbiddenLabels}
        sublabelFontSize={sublabelFontSize}
        sublabelType={sublabelType}
        allowLargeOptions={allowLargeOptions}
      />
    </div>
  )
}
